const novakomStreets = [
{"id": "177","name": "Булды (Пк)", },
{"id": "288","name": "Василия Скрипки (Пк)", },
{"id": "1204","name": "Венгерская (Пк)", },
{"id": "243","name": "Вишневского (Пк)", },
{"id": "260","name": "Вологодская (Пк)", },
{"id": "567","name": "Инструментальная (Пк)", },
{"id": "1163","name": "Кирило-Мефодиевская (Пк)", },
{"id": "776","name": "Музыкальная (Пк)", },
{"id": "1492","name": "Нарвская (Пк)", },
{"id": "796","name": "Невская (Пк)", },
{"id": "578","name": "Николая Светальского (С,Пк)", },
{"id": "883","name": "Парижская (Пк)", },
{"id": "819","name": "Ракитина (Пк)", },
{"id": "983","name": "Ревуцкого (Пк)", },
{"id": "1032","name": "Свирская (Пк)", },
{"id": "903","name": "Святоандреевская (Пк)", },
{"id": "987","name": "Сержанта Рзянкина (Пк)", },
{"id": "611","name": "Сечеславская (Пк)", },
{"id": "1091","name": "Синеводская (Пк)", },
{"id": "476","name": "Славная (Пк)", },
{"id": "1070","name": "Слободская (Пк)", },
{"id": "1102","name": "Станюковича (Пк)", },
{"id": "1130","name": "Таврическая (Пк)", },
{"id": "1254","name": "Фасадная (Пк)", },
{"id": "1415","name": "Шостаковича (Пк)", },
{"id": "1194","name": "Эдуарда Фукса (Пк)", },
{"id": "1444","name": "Электрическая (Пк)", },
{"id": "1047","name": "Юных моряков (Пк)", },
{"id": "1","name": "10-й Гвардейской дивизии (Т)", },
{"id": "2","name": "200-летия Кривого Рога (С)", },
{"id": "1614","name": "3-й Сальский пер.  (Мт)", },
{"id": "1856","name": "30-летия Победы (С)", },
{"id": "6","name": "4-й Заречный (Пк)", },
{"id": "1615","name": "4-й Сальский (Мт)", },
{"id": "8","name": "40-летия Победы (Т)", },
{"id": "9","name": "5-й Заречный м-н (Пк)", },
{"id": "13","name": "50-летия Победы (Пк)", },
{"id": "14","name": "7-й Заречный м-н (Пк)", },
{"id": "15","name": "9 Января (Пк)", },
{"id": "17","name": "Абаканская (Дг)", },
{"id": "606","name": "Абрикосовая (И)", },
{"id": "1626","name": "Абрикосовая (Т)", },
{"id": "18","name": "Абхазская (Мт)", },
{"id": "20","name": "Авангардная (Ц-Г)", },
{"id": "21","name": "Аварская (И)", },
{"id": "1627","name": "Августовская (И)", },
{"id": "23","name": "Авиационная (Ц-Г)", },
{"id": "24","name": "Авилова (Т)", },
{"id": "26","name": "Автозаводская (Ц-Г)", },
{"id": "27","name": "Автоматическая (Пк)", },
{"id": "28","name": "Автомобилистов (Ц-Г)", },
{"id": "29","name": "Авторская (Ц-Г)", },
{"id": "30","name": "Агафонова (Мт)", },
{"id": "31","name": "Аглостроевская (И)", },
{"id": "32","name": "Агрономическая (Мт)", },
{"id": "33","name": "Аджарский пер. (Ц-Г)", },
{"id": "34","name": "Адмирала Головко (Т)", },
{"id": "35","name": "Азиатская (И)", },
{"id": "38","name": "Азовская (Ц-Г)", },
{"id": "40","name": "Айвазовского (И)", },
{"id": "1313","name": "Академика Белелюбского (Ц-Г)", },
{"id": "1625","name": "Академика Губкина (Т)", },
{"id": "1118","name": "Академика Малахова (Ц-Г,Дз)", },
{"id": "41","name": "Академика Павлова (Т)", },
{"id": "42","name": "Академическая (Ц-Г)", },
{"id": "43","name": "Акмолинская (Мт)", },
{"id": "44","name": "Акопяна (Ц-Г)", },
{"id": "45","name": "Аксакова (И)", },
{"id": "46","name": "Актюбинская (Мт)", },
{"id": "1854","name": "Акционерная (Дг)", },
{"id": "172","name": "Александра Васякина (Дг,Дз)", },
{"id": "190","name": "Александра Олеся (С)", },
{"id": "1493","name": "Александра Поля (С)", },
{"id": "841","name": "Александра Поля (Ц-Г)", },
{"id": "5","name": "Александра Станкова (И)", },
{"id": "1543","name": "Александровская (Пк)", },
{"id": "1541","name": "Александровский пер1. (Ж)", },
{"id": "1542","name": "Александровский пер2. (Ж)", },
{"id": "47","name": "Алексеева пер. (Пк)", },
{"id": "716","name": "Алексеевская (Т)", },
{"id": "48","name": "Алексеенко (Ц-Г)", },
{"id": "603","name": "Алексея Соломенного (Т)", },
{"id": "49","name": "Алексея Щербака (Мт)", },
{"id": "50","name": "Алеутская (Дг)", },
{"id": "51","name": "Алехина (Ц-Г)", },
{"id": "1575","name": "Алима Солониченко (Т)", },
{"id": "52","name": "Алимова (Ц-Г)", },
{"id": "53","name": "Алитная (Дг)", },
{"id": "54","name": "Алма-атинская (И)", },
{"id": "919","name": "Алмазная (Ц-Г)", },
{"id": "1621","name": "Алупкинская (И)", },
{"id": "55","name": "Алуштинская (И)", },
{"id": "56","name": "Альпийская (Т)", },
{"id": "57","name": "Альпинистов пер. (Пк)", },
{"id": "58","name": "Алябьева (Пк)", },
{"id": "59","name": "Амосова (Ц-Г)", },
{"id": "60","name": "Ампера пер. (Пк)", },
{"id": "1529","name": "Амурская (Ц-Г)", },
{"id": "16","name": "Анатолия Желтухи (Ц-Г)", },
{"id": "511","name": "Анатолия Липицкого (Ц-Г)", },
{"id": "61","name": "Ангарская (И)", },
{"id": "63","name": "Андерсена (Дг)", },
{"id": "64","name": "Андижанская (И)", },
{"id": "309","name": "Анны Ярославовны (Ц-Г)", },
{"id": "66","name": "Аносова (Мт)", },
{"id": "67","name": "Антокольского (Дг)", },
{"id": "1105","name": "Апостоловская (Дг)", },
{"id": "1812","name": "Апрельская (И)", },
{"id": "69","name": "Аптечная (Пк)", },
{"id": "70","name": "Аральская (И)", },
{"id": "71","name": "Араратская (Пк)", },
{"id": "1623","name": "Арбатская (Ж)", },
{"id": "72","name": "Аргуновых (Ц-Г)", },
{"id": "73","name": "Арзамасская (С)", },
{"id": "74","name": "Аристова (Дг)", },
{"id": "75","name": "Аристотеля (Т)", },
{"id": "76","name": "Арктическая (Ц-Г)", },
{"id": "77","name": "Армавирская (Пк)", },
{"id": "1622","name": "Армейская (Ж)", },
{"id": "1544","name": "Армянская (Ц-Г)", },
{"id": "79","name": "Арсена (Т)", },
{"id": "1624","name": "Арсенальцев (Дз)", },
{"id": "81","name": "Артековский пер. (Пк)", },
{"id": "82","name": "Артиллеристов (Ц-Г)", },
{"id": "1628","name": "Архангельская (Дг)", },
{"id": "1629","name": "Архангельский пер. (Т)", },
{"id": "83","name": "Архимеда (Т)", },
{"id": "84","name": "Асеева (Пк)", },
{"id": "1630","name": "Асканийская (Ж)", },
{"id": "85","name": "Астраханская (Т)", },
{"id": "221","name": "Астрономическая (Т)", },
{"id": "86","name": "Аткарский пер. (Пк)", },
{"id": "87","name": "Атлантическая (Ц-Г)", },
{"id": "88","name": "Афиногенова (Ц-Г)", },
{"id": "1631","name": "Ахтырский пер. (Ж)", },
{"id": "89","name": "Ачинская (И)", },
{"id": "90","name": "Ашхабадская (И)", },
{"id": "1618","name": "Багратиона (И)", },
{"id": "96","name": "Багратиона (Ц-Г)", },
{"id": "97","name": "Багрицкого (Ц-Г)", },
{"id": "98","name": "Бажана (Ц-Г)", },
{"id": "1791","name": "Бажаново м-н. (Пк)", },
{"id": "1632","name": "Баженова (Ц-Г)", },
{"id": "99","name": "Бажова (Пк)", },
{"id": "1633","name": "Байдукова (Т)", },
{"id": "503","name": "Байды Вишневецкого (С,Пк)", },
{"id": "100","name": "Байкальская (Мт,С)", },
{"id": "1634","name": "Байконурская (Т)", },
{"id": "101","name": "Бакинская (Т)", },
{"id": "1635","name": "Бакинских комиссаров (Т)", },
{"id": "102","name": "Бакулева (Ц-Г)", },
{"id": "1636","name": "Балакирева (Ж)", },
{"id": "105","name": "Балашовская (Мт)", },
{"id": "106","name": "Балтийская (Ц-Г)", },
{"id": "107","name": "Балхашская (Ц-Г)", },
{"id": "108","name": "Бальзака (Ц-Г)", },
{"id": "1637","name": "Бамовская (Ц-Г)", },
{"id": "1462","name": "Бандуристов (Ц-Г)", },
{"id": "109","name": "Барановичский пер. (Пк)", },
{"id": "110","name": "Баратынского (Ц-Г)", },
{"id": "80","name": "Барачная (Т)", },
{"id": "112","name": "Бардина (Мт)", },
{"id": "114","name": "Барикадная (Ц-Г)", },
{"id": "113","name": "Барнаульская (Мт)", },
{"id": "115","name": "Басова (Дг)", },
{"id": "1638","name": "Батайский пер. (Ж)", },
{"id": "116","name": "Батумская (Пк)", },
{"id": "117","name": "Батуринская (Дг)", },
{"id": "1639","name": "Батюшкова (Ц-Г)", },
{"id": "119","name": "Баха (Дг)", },
{"id": "893","name": "Бахчисарайская (Ц-Г)", },
{"id": "120","name": "Башкирская (Дг)", },
{"id": "121","name": "Баштанная (Мт)", },
{"id": "122","name": "Бебеля пер. (Ц-Г)", },
{"id": "1640","name": "Безымянная (Дз)", },
{"id": "124","name": "Бекетова (Ц-Г)", },
{"id": "93","name": "Белая Горка (И)", },
{"id": "125","name": "Белгородская (Ц-Г)", },
{"id": "126","name": "Белецкого пер. (Ц-Г)", },
{"id": "1641","name": "Беловежская (Ж)", },
{"id": "1247","name": "Белогорская (Пк)", },
{"id": "128","name": "Беломорская (Ц-Г)", },
{"id": "129","name": "Белороссова (Ц-Г)", },
{"id": "130","name": "Белорусская (Ц-Г)", },
{"id": "131","name": "Белостоцкая (Пк)", },
{"id": "132","name": "Белых Акаций (Ц-Г)", },
{"id": "133","name": "Беляева (Дг)", },
{"id": "134","name": "Бердянская (С)", },
{"id": "135","name": "Береговая (Пк)", },
{"id": "137","name": "Березниковская (Пк)", },
{"id": "91","name": "Березногуватая (И)", },
{"id": "1642","name": "Березовская (Ц-Г)", },
{"id": "138","name": "Беринга (Ц-Г)", },
{"id": "139","name": "Берлинская (Ц-Г)", },
{"id": "1518","name": "Бестужева (Ц-Г)", },
{"id": "141","name": "Бетховена (Дг)", },
{"id": "142","name": "Бехтерева (Мт)", },
{"id": "143","name": "Библиотечный пер. (Т)", },
{"id": "144","name": "Бизе (Т)", },
{"id": "145","name": "Бирюзова (Т)", },
{"id": "147","name": "Благонравова (Ц-Г)", },
{"id": "149","name": "Блока (Ц-Г)", },
{"id": "151","name": "Бобруйская (Мт)", },
{"id": "152","name": "Богдана Хмельницкого (Т)", },
{"id": "153","name": "Богдановича (Ц-Г)", },
{"id": "1403","name": "Богдановская (Т)", },
{"id": "154","name": "Богомольца (Ц-Г)", },
{"id": "155","name": "Богуна (Ц-Г)", },
{"id": "156","name": "Бодрости (Ц-Г)", },
{"id": "566","name": "Божедаровская (Т)", },
{"id": "1643","name": "Бойля (Ж)", },
{"id": "158","name": "Болгарская (Ц-Г)", },
{"id": "159","name": "Болотникова (Дг)", },
{"id": "1799","name": "Больничная (Дз)", },
{"id": "1501","name": "Больничная (Т)", },
{"id": "148","name": "Большая Софиевская (Дг)", },
{"id": "1644","name": "Бора (Ц-Г)", },
{"id": "92","name": "Бориса Глинки (Ц-Г)", },
{"id": "1349","name": "Бориса Мозолевского (Ц-Г)", },
{"id": "161","name": "Бородина (Мт)", },
{"id": "162","name": "Ботева пер. (Ц-Г)", },
{"id": "163","name": "Боткина (Т)", },
{"id": "164","name": "Братиславская (Ц-Г)", },
{"id": "1449","name": "Братьев Кожакиных (С)", },
{"id": "165","name": "Брестская (Т)", },
{"id": "166","name": "Бруно (Мт)", },
{"id": "167","name": "Брюллова (Т)", },
{"id": "168","name": "Брянская (Ц-Г)", },
{"id": "171","name": "Будапештская (Пк)", },
{"id": "170","name": "Бужский пер. (Пк)", },
{"id": "958","name": "Бузиновая (Мт)", },
{"id": "174","name": "Буковая (Ц-Г)", },
{"id": "175","name": "Буковинская (Ц-Г)", },
{"id": "176","name": "Булавина (С)", },
{"id": "178","name": "Бульварный пер. (С)", },
{"id": "179","name": "Бунина (Дг)", },
{"id": "1645","name": "Бурденко (Дз)", },
{"id": "181","name": "Бурщицкая (И)", },
{"id": "182","name": "Бурятская (Дг)", },
{"id": "1646","name": "Бутакова (И)", },
{"id": "185","name": "Бутлерова пер. (Пк)", },
{"id": "186","name": "Бухарестская (И)", },
{"id": "187","name": "Бухарская (Пк)", },
{"id": "188","name": "Бучмы (Пк)", },
{"id": "189","name": "Быкова (С)", },
{"id": "1647","name": "Вавилова (Ж)", },
{"id": "192","name": "Вагнера (Мт)", },
{"id": "941","name": "Вадима Гурова (Мт,Дг)", },
{"id": "193","name": "Вакуленчука (Дг)", },
{"id": "194","name": "Валдайская (Пк)", },
{"id": "195","name": "Вали Котика (Т)", },
{"id": "197","name": "Ванина (Ц-Г)", },
{"id": "1648","name": "Варейкиса (Ц-Г)", },
{"id": "198","name": "Варлена пер. (Пк)", },
{"id": "200","name": "Вартаняна (Т)", },
{"id": "201","name": "Варшавский пер. (Пк)", },
{"id": "203","name": "Василенко (И)", },
{"id": "1843","name": "Василенко (Ц-Г)", },
{"id": "450","name": "Василия Жуковского (Пк)", },
{"id": "204","name": "Василия Найденко (Т)", },
{"id": "123","name": "Василия Симоненка (Дг)", },
{"id": "595","name": "Василия Скопенка (Мт)", },
{"id": "848","name": "Василия Суманеева (Ц-Г)", },
{"id": "1649","name": "Васильченко (И)", },
{"id": "205","name": "Васнецова (С)", },
{"id": "206","name": "Ватутина (Пк)", },
{"id": "208","name": "Вахтангова (Ц-Г)", },
{"id": "209","name": "Вековая (Ц-Г)", },
{"id": "210","name": "Великолуцкая (Т)", },
{"id": "211","name": "Величкиной пер. (Т)", },
{"id": "1801","name": "Величко (Т)", },
{"id": "212","name": "Венецианова (Ц-Г)", },
{"id": "213","name": "Вербицкого (Пк)", },
{"id": "214","name": "Вербова (Т)", },
{"id": "215","name": "Верди (Пк)", },
{"id": "216","name": "Вересаева (Пк)", },
{"id": "217","name": "Верещагина (Пк)", },
{"id": "199","name": "Вернадского (Дг)", },
{"id": "219","name": "Верстовского (Т)", },
{"id": "196","name": "Верхняя Антоновка (Ц-Г)", },
{"id": "220","name": "Верхоянская (Ц-Г)", },
{"id": "222","name": "Вершинина (Ц-Г)", },
{"id": "223","name": "Веры Пановой (Пк)", },
{"id": "224","name": "Веселая (И)", },
{"id": "225","name": "Веселая дача (Ц-Г)", },
{"id": "226","name": "Веселопольская (Дг)", },
{"id": "596","name": "Веселотерновская (Т)", },
{"id": "227","name": "Весенняя (Ц-Г)", },
{"id": "229","name": "Ветеранов (Пк)", },
{"id": "230","name": "Ветеранов труда (Мт)", },
{"id": "231","name": "Вечернекутская (Дг)", },
{"id": "232","name": "Вечерний б-р. (С)", },
{"id": "233","name": "Вешенская (Пк)", },
{"id": "234","name": "Вивальди (Ц-Г)", },
{"id": "228","name": "Виктора Гошкевича (Дг)", },
{"id": "78","name": "Виктора Кайстренко (Т)", },
{"id": "235","name": "Вильнюсская (Дг)", },
{"id": "236","name": "Вильямса (Ц-Г)", },
{"id": "237","name": "Винницкая (Дг)", },
{"id": "238","name": "Виноградная (С)", },
{"id": "239","name": "Виноградова (Пк)", },
{"id": "1651","name": "Винтера пер. (Т)", },
{"id": "1652","name": "Вирты (Ц-Г)", },
{"id": "1499","name": "Виталия Матусевича (Мт)", },
{"id": "240","name": "Витебская (С)", },
{"id": "242","name": "Вишневая (Ц-Г)", },
{"id": "244","name": "Владивостокская (Пк)", },
{"id": "1491","name": "Владимира Бызова (Мт)", },
{"id": "731","name": "Владимира Великого (С)", },
{"id": "1029","name": "Владимира Винниченко (Ц-Г)", },
{"id": "1611","name": "Владимира Михайличенко(Т)", },
{"id": "1256","name": "Владимира Терещенко (Т)", },
{"id": "245","name": "Владимировская (Дг)", },
{"id": "246","name": "Водная (Пк)", },
{"id": "247","name": "Водный пер. (Ц-Г)", },
{"id": "270","name": "Водопьянова (Дг)", },
{"id": "248","name": "Военный городок-1 (Ц-Г)", },
{"id": "1513","name": "Военный городок-33 (Дг)", },
{"id": "249","name": "Военный городок-35 (Ц-Г)", },
{"id": "268","name": "Вознесенского (Ц-Г)", },
{"id": "252","name": "Войнич (Пк)", },
{"id": "254","name": "Вокзальная (Мт)", },
{"id": "255","name": "Волгоградская (С)", },
{"id": "256","name": "Волейбольная (Ц-Г)", },
{"id": "257","name": "Волжская (Мт)", },
{"id": "258","name": "Волина (Ц-Г)", },
{"id": "259","name": "Волкова (Пк)", },
{"id": "262","name": "Волосевича (С)", },
{"id": "1847","name": "Волошкова (Ц-Г)", },
{"id": "263","name": "Волховская (Ц-Г)", },
{"id": "264","name": "Волынская (Ц-Г)", },
{"id": "265","name": "Вольная (С)", },
{"id": "218","name": "Вольного козачества (Т)", },
{"id": "266","name": "Вольская (Ц-Г)", },
{"id": "267","name": "Вольтера (Дг)", },
{"id": "269","name": "Воронежская (Дг)", },
{"id": "1547","name": "Ворошилова (Т)", },
{"id": "1653","name": "Воскресная (Ж)", },
{"id": "271","name": "Восточная (Т)", },
{"id": "272","name": "Врубеля пер. (Ц-Г)", },
{"id": "273","name": "Всебратское-2 (Ц-Г)", },
{"id": "274","name": "Встречная (Пк)", },
{"id": "275","name": "Вургуна (Пк)", },
{"id": "1654","name": "Вучетича (Пк)", },
{"id": "277","name": "Выборгская (Мт)", },
{"id": "276","name": "Выборная (Пк)", },
{"id": "278","name": "Высоковольтная (Т)", },
{"id": "279","name": "Вьетнамская (Пк)", },
{"id": "280","name": "Вязовая (Т)", },
{"id": "281","name": "Вятская (Дг)", },
{"id": "357","name": "Вячеслава Чорновола (Дг)", },
{"id": "1655","name": "Гаврилюка (Ж)", },
{"id": "283","name": "Гагарина пр. (Мт,Дг,С)", },
{"id": "285","name": "Гаджибекова (С)", },
{"id": "286","name": "Газетная (Ц-Г)", },
{"id": "1656","name": "Газовая (Дг)", },
{"id": "1657","name": "Гайдара (И)", },
{"id": "289","name": "Галатова (Мт)", },
{"id": "1149","name": "Галаховская (Ц-Г)", },
{"id": "290","name": "Галенко (С,Дз)", },
{"id": "291","name": "Галилея (С)", },
{"id": "502","name": "Галковский Кут (С)", },
{"id": "1795","name": "Гарибальди (И)", },
{"id": "293","name": "Гарина (Ц-Г)", },
{"id": "294","name": "Гаркуши (Пк)", },
{"id": "1610","name": "Гарнизонная (Ц-Г)", },
{"id": "296","name": "Гаршина (Дг)", },
{"id": "1808","name": "Гаршина пер.(Дг)", },
{"id": "298","name": "Гастелло (Т)", },
{"id": "299","name": "Гафури (С)", },
{"id": "300","name": "Гашека (Дг)", },
{"id": "301","name": "Гвардейская (Пк)", },
{"id": "302","name": "Гданская (И)", },
{"id": "1503","name": "Гданцевская (Ц-Г)", },
{"id": "303","name": "Геленджикская (С)", },
{"id": "625","name": "Генерала Кузнецова (С,Пк)", },
{"id": "1195","name": "Генерала Радиевского (С)", },
{"id": "304","name": "Геническая (С)", },
{"id": "493","name": "Геннадия Удовенко (Ц-Г)", },
{"id": "305","name": "Геодезическая (И)", },
{"id": "306","name": "Геологическая (Ц-Г)", },
{"id": "1548","name": "Георга Отса (Пк)", },
{"id": "396","name": "Героев АТО (Мт,Дг)", },
{"id": "307","name": "Героев Бреста (Т)", },
{"id": "598","name": "Героев Крут (Т)", },
{"id": "392","name": "Героев-подпольщиков (С)", },
{"id": "308","name": "Героизма (И)", },
{"id": "310","name": "Герцена (Ц-Г)", },
{"id": "311","name": "Гете (Ц-Г)", },
{"id": "480","name": "Гетманская (С,Дз)", },
{"id": "312","name": "Гжатская (И)", },
{"id": "313","name": "Гизенги пер. (Ц-Г)", },
{"id": "314","name": "Гималайская (И)", },
{"id": "315","name": "Глаголева (Пк)", },
{"id": "1658","name": "Гладкова (Ц-Г)", },
{"id": "316","name": "Глазунова (Т)", },
{"id": "317","name": "Глезоса (Ц-Г,Дз)", },
{"id": "318","name": "Глинки (Ц-Г)", },
{"id": "1023","name": "Говерловская (Дг)", },
{"id": "319","name": "Говорова (Т)", },
{"id": "320","name": "Гоголя (И)", },
{"id": "1800","name": "Гоголя пер. (Ц-Г)", },
{"id": "287","name": "Гойи (Дг)", },
{"id": "322","name": "Голубиная (Ц-Г)", },
{"id": "323","name": "Гомельская (Пк)", },
{"id": "324","name": "Гомера (Пк)", },
{"id": "325","name": "Гончарова (Ц-Г)", },
{"id": "326","name": "Горбаченко (Дг)", },
{"id": "327","name": "Горийская (Ц-Г)", },
{"id": "328","name": "Горловская (И)", },
{"id": "329","name": "Горно-алтайская (Пк)", },
{"id": "1331","name": "Горнозаводская (Ц-Г)", },
{"id": "330","name": "Горнорудный пер. (Т)", },
{"id": "1507","name": "Горноспасательная (С)", },
{"id": "12","name": "Горняков (И)", },
{"id": "1659","name": "Горняков (Т)", },
{"id": "1601","name": "Горняцкая (И)", },
{"id": "975","name": "Горняцкий м-н (С)", },
{"id": "1857","name": "Горняцкой Славы (И)", },
{"id": "331","name": "Горняцкой Славы (Ц-Г)", },
{"id": "332","name": "Городищенская (С)", },
{"id": "334","name": "Горького (Ц-Г)", },
{"id": "1607","name": "Горького пл. (С)", },
{"id": "337","name": "Грабовского (Ц-Г)", },
{"id": "338","name": "Граве (Пк)", },
{"id": "1660","name": "Гражданская (И)", },
{"id": "339","name": "Гранитная (Пк)", },
{"id": "340","name": "Гребенки (Дг)", },
{"id": "341","name": "Грекова (Пк)", },
{"id": "342","name": "Грибоедова (Пк)", },
{"id": "343","name": "Грига (Ц-Г)", },
{"id": "713","name": "Григория Косынки (Т)", },
{"id": "1775","name": "Григория Лаврика (Пк)", },
{"id": "333","name": "Григория Шишко (Ц-Г)", },
{"id": "344","name": "Григоровича (Пк)", },
{"id": "460","name": "Григоровская (И)", },
{"id": "345","name": "Гризодубовой (Ц-Г)", },
{"id": "346","name": "Грина (Ц-Г)", },
{"id": "347","name": "Грицевца (Т)", },
{"id": "348","name": "Грозненская (Дг)", },
{"id": "349","name": "Грозовая (Пк)", },
{"id": "350","name": "Громова (Ц-Г)", },
{"id": "351","name": "Громовой (И)", },
{"id": "353","name": "Грузинская (Т)", },
{"id": "1860","name": "Грузовая (Дг)", },
{"id": "336","name": "Груни Романовой (И)", },
{"id": "1661","name": "Грушевая (И)", },
{"id": "354","name": "Грушевского (Дг)", },
{"id": "1388","name": "Грушовая (Ц-Г)", },
{"id": "542","name": "Грядковатая (Т)", },
{"id": "282","name": "Гулака-Артемовского (Ц-Г)", },
{"id": "355","name": "Гуренко (И)", },
{"id": "358","name": "Гуцульская (Дг)", },
{"id": "359","name": "Гюго (Пк)", },
{"id": "1387","name": "Давида Кана (Ц-Г)", },
{"id": "364","name": "Давыдова (Дг)", },
{"id": "363","name": "Давыдовского пер. (Пк)", },
{"id": "365","name": "Дагестанская (Ц-Г)", },
{"id": "366","name": "Дадиани (Дг)", },
{"id": "367","name": "Далекая (Ц-Г)", },
{"id": "368","name": "Дальневосточная (Т)", },
{"id": "369","name": "Даля (Ц-Г)", },
{"id": "370","name": "Данилевского (Пк)", },
{"id": "371","name": "Дарвина (С)", },
{"id": "372","name": "Даргомыжского пер. (Ц-Г)", },
{"id": "373","name": "Дачная (Ц-Г)", },
{"id": "374","name": "Двинская (Т)", },
{"id": "375","name": "Дворжака (И)", },
{"id": "376","name": "Дворникова (Т)", },
{"id": "1809","name": "Дежнева пер.(Ц-Г)", },
{"id": "377","name": "Дежньова (Ц-Г)", },
{"id": "378","name": "Декабристов (Ц-Г)", },
{"id": "379","name": "Декабрьская (Ц-Г)", },
{"id": "395","name": "Деловая (Пк)", },
{"id": "380","name": "Демиденко (С)", },
{"id": "381","name": "Демокрита (Ц-Г)", },
{"id": "1662","name": "Демченко (Т)", },
{"id": "382","name": "Демьянова (Пк)", },
{"id": "383","name": "Деповский пер. (Дг)", },
{"id": "1663","name": "Державина (Ж)", },
{"id": "384","name": "Десантная (Пк)", },
{"id": "1664","name": "Десняка (Ц-Г)", },
{"id": "385","name": "Детства (С)", },
{"id": "386","name": "Джалиля (Пк)", },
{"id": "387","name": "Джамбула (С,Пк)", },
{"id": "388","name": "Джанкойская (Ц-Г)", },
{"id": "362","name": "Джека Лондона (Мт)", },
{"id": "393","name": "Дидро (С)", },
{"id": "394","name": "Диккенса (Ц-Г)", },
{"id": "253","name": "Дмитрия Войчишина (Т)", },
{"id": "1805","name": "Дмитрия Глинки (Ц-Г)", },
{"id": "412","name": "Дмитрия Донского (С)", },
{"id": "398","name": "Днепровская (С)", },
{"id": "399","name": "Днепровское шоссе (Дг)", },
{"id": "400","name": "Днестровская (С)", },
{"id": "401","name": "Доблести пер. (Ц-Г)", },
{"id": "402","name": "Добролюбова (И)", },
{"id": "403","name": "Добронравова (Дг)", },
{"id": "404","name": "Доватора (Т)", },
{"id": "405","name": "Довженко (Ц-Г)", },
{"id": "406","name": "Дождевая (Ц-Г)", },
{"id": "407","name": "Докучаева (Ц-Г)", },
{"id": "408","name": "Долгинцевский пер. (Мт)", },
{"id": "409","name": "Должанского (Т)", },
{"id": "410","name": "Домностроителей пл. (Мт,Дг)", },
{"id": "1665","name": "Домостроительная (Мт)", },
{"id": "411","name": "Донецкая (Дг)", },
{"id": "413","name": "Дорожный пер. (Мт)", },
{"id": "911","name": "Досвитная (Пк)", },
{"id": "414","name": "Достоевского (Дг)", },
{"id": "415","name": "Драйзера (Пк)", },
{"id": "1666","name": "Дрогобычская (С)", },
{"id": "416","name": "Дружбы (И)", },
{"id": "569","name": "Дружеская (Ц-Г)", },
{"id": "580","name": "Дубки (С)", },
{"id": "160","name": "Дубовая Балка (Пк)", },
{"id": "753","name": "Дубовая Гора (И)", },
{"id": "1667","name": "Дубовая гора (И)", },
{"id": "417","name": "Дунаевского (С)", },
{"id": "418","name": "Дунайская (Ц-Г)", },
{"id": "419","name": "Душанбинская (Ц-Г)", },
{"id": "397","name": "Дышинского (Пк)", },
{"id": "421","name": "Дюма (Ц-Г)", },
{"id": "422","name": "Евпаторийская (Мт)", },
{"id": "540","name": "Европейский (Дг)", },
{"id": "424","name": "Единства (Ц-Г)", },
{"id": "722","name": "Ежевичный (Т)", },
{"id": "425","name": "Ейский пер. (Мт)", },
{"id": "426","name": "Елецкая (Ц-Г)", },
{"id": "427","name": "Елизаровский пер. (Т)", },
{"id": "1853","name": "Елочная (И)", },
{"id": "428","name": "Емельянова (Ц-Г)", },
{"id": "429","name": "Енисейская (Пк)", },
{"id": "430","name": "Ереванская (Т)", },
{"id": "431","name": "Ермоловой (Ц-Г)", },
{"id": "432","name": "Ершова пер. (И)", },
{"id": "433","name": "Есенина (Мт,С)", },
{"id": "1668","name": "Ефремова пер. (Дз)", },
{"id": "436","name": "Железная (С)", },
{"id": "434","name": "Железноводская (Мт)", },
{"id": "1532","name": "Железнодорожная (Т)", },
{"id": "662","name": "Железнодорожников (Дг)", },
{"id": "435","name": "Железнорудная (Мт)", },
{"id": "439","name": "Желтонога (И)", },
{"id": "440","name": "Желтореченская (Т)", },
{"id": "441","name": "Желябова (Ц-Г)", },
{"id": "442","name": "Жемчужная (Т)", },
{"id": "443","name": "Женевская (Пк)", },
{"id": "1669","name": "Жертв Революции (Мт)", },
{"id": "444","name": "Живописная (Дг)", },
{"id": "490","name": "Живописная (Дг)", },
{"id": "445","name": "Жигулевская (Дг)", },
{"id": "446","name": "Житомирская (С)", },
{"id": "447","name": "Жмеринская (Ц-Г)", },
{"id": "448","name": "Жмеринский пер. (Ц-Г)", },
{"id": "438","name": "Жолтовского пер. (Ц-Г)", },
{"id": "451","name": "Журавского (Ц-Г)", },
{"id": "452","name": "Журнальная (И)", },
{"id": "453","name": "Заводская (Ц-Г)", },
{"id": "454","name": "Закарпатская (Т)", },
{"id": "1844","name": "Зализнычни Будынкы (Ц-Г)", },
{"id": "455","name": "Заньковецкой (Дг)", },
{"id": "456","name": "Заозерная (Дг)", },
{"id": "68","name": "Заплавная (Т)", },
{"id": "1297","name": "Заповедная (Ц-Г)", },
{"id": "457","name": "Запорожская (Ц-Г)", },
{"id": "458","name": "Заречная (Ц-Г)", },
{"id": "459","name": "Заслонова пер. (Ц-Г)", },
{"id": "1754","name": "Звездная (Мт)", },
{"id": "461","name": "Звездная (Ц-Г)", },
{"id": "465","name": "Зеленая (Т)", },
{"id": "462","name": "Зеленовская (И)", },
{"id": "463","name": "Зеленоградская (Пк)", },
{"id": "464","name": "Зеленый гай (Ц-Г)", },
{"id": "467","name": "Земнухова (И)", },
{"id": "468","name": "Зимняя (Ц-Г)", },
{"id": "1263","name": "Зимовникова (И)", },
{"id": "1066","name": "Зимовского (Пк)", },
{"id": "1670","name": "Златоустовская (Ц-Г)", },
{"id": "1671","name": "Знаменская (Ц-Г)", },
{"id": "469","name": "Знаменских пер. (Ц-Г)", },
{"id": "1672","name": "Знаний (Ц-Г)", },
{"id": "470","name": "Зои Космодемьянской (Т)", },
{"id": "471","name": "Зорге (Ц-Г)", },
{"id": "472","name": "Зореносная (Пк)", },
{"id": "575","name": "Ивана Авраменко (С)", },
{"id": "118","name": "Ивана Добровольского (Ц-Г)", },
{"id": "180","name": "Ивана Мазепы (Пк)", },
{"id": "473","name": "Ивана Сирко (Т)", },
{"id": "633","name": "Ивана Сулимы (Ц-Г)", },
{"id": "1586","name": "Ивана Франко (Ц-Г)", },
{"id": "474","name": "Ивановская (Дг)", },
{"id": "475","name": "Игарская (Т)", },
{"id": "1811","name": "Игарский пер.(Т)", },
{"id": "619","name": "Игоря Сикорского (Дг)", },
{"id": "477","name": "Игровая (Дг)", },
{"id": "478","name": "Ижевская (И)", },
{"id": "479","name": "Изобилия (Ц-Г)", },
{"id": "1673","name": "Ильича (Дз)", },
{"id": "481","name": "Ильменская (Пк)", },
{"id": "482","name": "Ингулецкая (Ц-Г)", },
{"id": "541","name": "Ингульская (Ц-Г)", },
{"id": "1590","name": "Ингушская (Мт)", },
{"id": "483","name": "Индиры Ганди (Т)", },
{"id": "484","name": "Индустриальная (Пк)", },
{"id": "485","name": "Индустриальный м-н. (Пк)", },
{"id": "356","name": "Ионовая Балка (Ц-Г)", },
{"id": "1220","name": "Иорданская (Ц-Г)", },
{"id": "25","name": "Иосифа Пачоского (И)", },
{"id": "487","name": "Иркутская (Мт)", },
{"id": "488","name": "Иртышская (Пк)", },
{"id": "489","name": "Исаковского (Ц-Г)", },
{"id": "491","name": "Историческая (Пк)", },
{"id": "675","name": "Июльская (С)", },
{"id": "494","name": "Кабардинская (Ц-Г)", },
{"id": "495","name": "Кавказская (Ц-Г)", },
{"id": "496","name": "Кадиевская (Ц-Г)", },
{"id": "1674","name": "Казановой пер. (Ц-Г)", },
{"id": "1803","name": "Казарма (Дг)", },
{"id": "497","name": "Казарма 109 км (Дг)", },
{"id": "499","name": "Казарма 111 км (Ц-Г)", },
{"id": "498","name": "Казахская (Пк)", },
{"id": "500","name": "Казачий пер. (Ц-Г)", },
{"id": "501","name": "Калантая (Пк)", },
{"id": "1824","name": "Калиновая (И)", },
{"id": "907","name": "Калиновый (Мт)", },
{"id": "505","name": "Калужская (Ц-Г)", },
{"id": "506","name": "Кальмана (Пк)", },
{"id": "508","name": "Каменная (Т)", },
{"id": "509","name": "Каменская (Пк)", },
{"id": "507","name": "Камнедробильная (Ц-Г)", },
{"id": "565","name": "Кампанеллы (И)", },
{"id": "1675","name": "Камская (Ц-Г)", },
{"id": "512","name": "Камчатская (Ц-Г)", },
{"id": "562","name": "Камышанская (Пк)", },
{"id": "513","name": "Канарская (Т)", },
{"id": "1266","name": "Кандыбовская (Ц-Г)", },
{"id": "514","name": "Карагандинская (Ц-Г)", },
{"id": "515","name": "Каракалпакская (Ц-Г)", },
{"id": "516","name": "Каракозова (Ц-Г)", },
{"id": "517","name": "Карачаевская (Т)", },
{"id": "518","name": "Карбышева (Т)", },
{"id": "519","name": "Карельская (С)", },
{"id": "521","name": "Кармелюка (И)", },
{"id": "522","name": "Карнавальная (Пк)", },
{"id": "627","name": "Карнаватская (Ц-Г)", },
{"id": "523","name": "Карпенко-Карого (Дг)", },
{"id": "1676","name": "Карьерная (Ц-Г)", },
{"id": "1677","name": "Касаткина пер. (Т)", },
{"id": "524","name": "Каспийская (С)", },
{"id": "1678","name": "Кассиля (И)", },
{"id": "525","name": "Кастуся Калиновского (Т)", },
{"id": "712","name": "Катериновская (С)", },
{"id": "526","name": "Каткова (И)", },
{"id": "527","name": "Каунасская (Ц-Г)", },
{"id": "528","name": "Каховская (Мт)", },
{"id": "530","name": "Качалова (С)", },
{"id": "1508","name": "Каширская (Т)", },
{"id": "531","name": "Каштанов (Мт)", },
{"id": "1437","name": "Каштановая (Т)", },
{"id": "532","name": "Квартальная (Пк)", },
{"id": "533","name": "Кемеровская (Т)", },
{"id": "534","name": "Керчинская (С)", },
{"id": "535","name": "Кибальчича (Т)", },
{"id": "536","name": "Киевская (Мт)", },
{"id": "538","name": "Киргизская (И)", },
{"id": "543","name": "Кисловодская (Пк)", },
{"id": "544","name": "Кишиневская (Дг)", },
{"id": "545","name": "Клайпедский пер. (Ц-Г)", },
{"id": "546","name": "Кленовая (Ц-Г)", },
{"id": "111","name": "Кленовый (Ц-Г)", },
{"id": "547","name": "Климова (Дг)", },
{"id": "548","name": "Клиническая (Пк)", },
{"id": "1679","name": "Клочкова (И)", },
{"id": "1509","name": "Клубная (С)", },
{"id": "1680","name": "Ключевой пер. (Ж)", },
{"id": "549","name": "Книжная (Мт)", },
{"id": "1166","name": "Княгини Ольги (С)", },
{"id": "550","name": "Кобылянского (Ц-Г)", },
{"id": "551","name": "Ковельская (Т)", },
{"id": "552","name": "Ковпака пер. (Т)", },
{"id": "553","name": "Ковровская (Дг)", },
{"id": "1217","name": "Козацкой славы (С)", },
{"id": "1516","name": "Козаченко (С)", },
{"id": "554","name": "Койнаша (Дг)", },
{"id": "555","name": "Кокандская (Ц-Г)", },
{"id": "556","name": "Кокотина (Дг)", },
{"id": "557","name": "Коксохимовская (Мт)", },
{"id": "558","name": "Кокчетавская (Дг)", },
{"id": "1681","name": "Коларова пер. (Дз)", },
{"id": "1682","name": "Коломенская (И)", },
{"id": "1855","name": "Коломойцевская (Ж,С)", },
{"id": "1249","name": "Колонковская (Пк)", },
{"id": "1683","name": "Колхозная (Ц-Г)", },
{"id": "1684","name": "Колыбельная (Т)", },
{"id": "560","name": "Кольцевая (С)", },
{"id": "1524","name": "Комарова (Т)", },
{"id": "1500","name": "Комерческая (Ц-Г)", },
{"id": "568","name": "Кондукторская (Ц-Г)", },
{"id": "570","name": "Конотопская (Т)", },
{"id": "571","name": "Конституционная (Пк)", },
{"id": "572","name": "Концертная (Т)", },
{"id": "1077","name": "Кооперативная (Мт)", },
{"id": "573","name": "Коперника (Ц-Г)", },
{"id": "574","name": "Копыленко (Ц-Г)", },
{"id": "577","name": "Королева (Т)", },
{"id": "576","name": "Короленко (Т)", },
{"id": "437","name": "Корсунская (С)", },
{"id": "579","name": "Корсунский  пер. (Т)", },
{"id": "1685","name": "Корякский пер. (Ц-Г)", },
{"id": "582","name": "Космонавтов (С)", },
{"id": "583","name": "Костенко (Мт,С)", },
{"id": "1686","name": "Костопольская (С)", },
{"id": "584","name": "Костромская (Ц-Г)", },
{"id": "1687","name": "Костычева (Т)", },
{"id": "585","name": "Костюшко (Т)", },
{"id": "692","name": "Костя Пестушка (Ц-Г)", },
{"id": "1688","name": "Котельникова пер. (С)", },
{"id": "587","name": "Котляревского (Дг)", },
{"id": "589","name": "Коцюбинского (Пк)", },
{"id": "590","name": "Кочубея (И)", },
{"id": "591","name": "Кошута (Мт)", },
{"id": "592","name": "Кравченко (Ц-Г)", },
{"id": "593","name": "Краковская (Ц-Г)", },
{"id": "1689","name": "Краматорская (Т)", },
{"id": "594","name": "Крамского (Т)", },
{"id": "1690","name": "Красная площадь (Т)", },
{"id": "597","name": "Красноводская (С)", },
{"id": "157","name": "Красногорская (И)", },
{"id": "600","name": "Красноградская (Ц-Г)", },
{"id": "1691","name": "Красноуфимская (С)", },
{"id": "607","name": "Красноярская (Т)", },
{"id": "608","name": "Красный Октябрь (Мт)", },
{"id": "609","name": "Красных роз (Ц-Г)", },
{"id": "610","name": "Кременчугская (Ц-Г)", },
{"id": "612","name": "Кренкеля (Т)", },
{"id": "1813","name": "Кренкеля пер.(Т)", },
{"id": "613","name": "Кривбассовская (Ц-Г)", },
{"id": "1692","name": "Криворожская (Т)", },
{"id": "853","name": "Криворожстали (Мт)", },
{"id": "614","name": "Криничанская (Дг)", },
{"id": "1414","name": "Криничная (Ц-Г)", },
{"id": "615","name": "Кронштадтская (И)", },
{"id": "616","name": "Кропивницкого (Пк)", },
{"id": "618","name": "Крутогорная (Ц-Г)", },
{"id": "1330","name": "Крутояровская (Пк)", },
{"id": "620","name": "Крылова (Мт)", },
{"id": "621","name": "Крымская (Пк)", },
{"id": "1839","name": "Крэсовский пер.(Ж)", },
{"id": "1804","name": "Крэсовский пос.(Пк)", },
{"id": "1693","name": "Крюкова (Ц-Г)", },
{"id": "622","name": "Кубанская (С)", },
{"id": "623","name": "Кубинская (Дг)", },
{"id": "624","name": "Кузнецкая (Дг)", },
{"id": "626","name": "Кузнечная (С)", },
{"id": "628","name": "Куликовская (Пк)", },
{"id": "630","name": "Куприна (Ц-Г)", },
{"id": "631","name": "Курганская (С)", },
{"id": "632","name": "Курильская (Т)", },
{"id": "634","name": "Курская (Мт)", },
{"id": "635","name": "Курчатова (С,Пк)", },
{"id": "636","name": "Курячего (Ц-Г)", },
{"id": "1694","name": "Кустанайская (С)", },
{"id": "637","name": "Кутаисская (Ц-Г)", },
{"id": "1695","name": "Кутузова (И)", },
{"id": "638","name": "Кюри (Ц-Г)", },
{"id": "643","name": "Лабораторная (Ц-Г)", },
{"id": "644","name": "Лавочкина (Пк)", },
{"id": "645","name": "Лавренева (С)", },
{"id": "646","name": "Лавроненко (Ц-Г)", },
{"id": "647","name": "Лагерная (Ц-Г)", },
{"id": "648","name": "Ладожская (Пк)", },
{"id": "649","name": "Ладыгина (Ц-Г)", },
{"id": "651","name": "Ландау (Мт)", },
{"id": "653","name": "Латвийская (Т)", },
{"id": "1605","name": "Латовка ст. (И)", },
{"id": "654","name": "Лауреатов (Ц-Г)", },
{"id": "655","name": "Лафарга (Дг)", },
{"id": "657","name": "Лаштобы (Т)", },
{"id": "640","name": "Лебедева-Кумача пл. (Ц-Г)", },
{"id": "658","name": "Лебединского (Ц-Г)", },
{"id": "659","name": "Левитана (Дг)", },
{"id": "660","name": "Левицкого (И)", },
{"id": "1696","name": "Лейпцигский пер. (Т)", },
{"id": "639","name": "Лени Голикова (И)", },
{"id": "1697","name": "Ленинградская (Ц-Г)", },
{"id": "1510","name": "Ленинская подстанция (Т)", },
{"id": "664","name": "Ленская (Т)", },
{"id": "11","name": "Леонида Бородича (Дг)", },
{"id": "667","name": "Леонида Быкова (Дг)", },
{"id": "665","name": "Леонова (Ц-Г)", },
{"id": "284","name": "Леонтовича (Ц-Г)", },
{"id": "668","name": "Лермонтова (Ц-Г)", },
{"id": "641","name": "Леси Украинки (Мт)", },
{"id": "1699","name": "Лескова (Т)", },
{"id": "669","name": "Лесная (Дг)", },
{"id": "670","name": "Лесоводов (Мт)", },
{"id": "1698","name": "Лесфага (Ц-Г)", },
{"id": "671","name": "Летная (И)", },
{"id": "663","name": "Летчиков (С)", },
{"id": "672","name": "Лечебный пер. (Дг)", },
{"id": "642","name": "Лизы Чайкиной (Мт)", },
{"id": "673","name": "Линейная (Ц-Г)", },
{"id": "674","name": "Липецкая (Дг)", },
{"id": "676","name": "Лисичанская (И)", },
{"id": "677","name": "Лисового (Дг)", },
{"id": "678","name": "Листа (И)", },
{"id": "679","name": "Листопадова (И)", },
{"id": "1700","name": "Литвинова (Ц-Г)", },
{"id": "681","name": "Литейная (Ц-Г)", },
{"id": "682","name": "Литке (Дг)", },
{"id": "683","name": "Литовская (Т)", },
{"id": "685","name": "Лобачевского (Дг)", },
{"id": "1814","name": "Лодыгина пер.(Ц-Г)", },
{"id": "686","name": "Лозоватская (Ц-Г)", },
{"id": "563","name": "Локомотивная (Дг)", },
{"id": "687","name": "Ломоносова (Т)", },
{"id": "688","name": "Луганская (Т)", },
{"id": "689","name": "Луговая (Дг)", },
{"id": "690","name": "Луговского пер. (Т)", },
{"id": "691","name": "Лумумбы (И)", },
{"id": "1701","name": "Лунина (Ц-Г)", },
{"id": "693","name": "Лунная (Т)", },
{"id": "694","name": "Луцкая (Т)", },
{"id": "695","name": "Лысенко (И)", },
{"id": "680","name": "Лысяка (Пк)", },
{"id": "696","name": "Львовская (Дг)", },
{"id": "697","name": "Люберецкая (Т)", },
{"id": "1702","name": "Магаданская (Дг)", },
{"id": "701","name": "Магеллана (Т)", },
{"id": "1815","name": "Магеллана пер.(Т)", },
{"id": "539","name": "Магистральная (Дг)", },
{"id": "702","name": "Магнитогорская (С)", },
{"id": "703","name": "Майкопская (Пк)", },
{"id": "1170","name": "Майская (Мт)", },
{"id": "704","name": "Макаренко (И)", },
{"id": "705","name": "Макарова (Т)", },
{"id": "706","name": "Макеевская (И)", },
{"id": "601","name": "Максима Кривоноса (Ц-Г)", },
{"id": "707","name": "Малиновая (Пк)", },
{"id": "708","name": "Малиновского (Т)", },
{"id": "709","name": "Малоархангельская (Дг)", },
{"id": "710","name": "Малыгина (Ц-Г)", },
{"id": "449","name": "Мальвовая (Мт)", },
{"id": "700","name": "Мамина-Сибиряка (Ц-Г)", },
{"id": "1816","name": "Марии Ульяновой пер.(Т)", },
{"id": "714","name": "Марийская (С)", },
{"id": "715","name": "Марины Расквой пер.(Т)", },
{"id": "718","name": "Мариупольская (Ц-Г)", },
{"id": "1737","name": "Марка Вовчка (Дг)", },
{"id": "564","name": "Мартина Шимановского (Ц-Г)", },
{"id": "719","name": "Мартовича (И)", },
{"id": "136","name": "Мартовская(Дг)", },
{"id": "720","name": "Маршака (Т)", },
{"id": "202","name": "Маршала Василевского (Пк)", },
{"id": "721","name": "Маршевая (Ц-Г)", },
{"id": "723","name": "Маршрутная (Ц-Г)", },
{"id": "724","name": "Масштабная (Пк)", },
{"id": "725","name": "Матросова (Т)", },
{"id": "261","name": "Матрьоновская (И)", },
{"id": "1703","name": "Махачкалинская (Ц-Г)", },
{"id": "726","name": "Машиностроительная (Ц-Г)", },
{"id": "1604","name": "Маяковского (И)", },
{"id": "727","name": "Маяковского (С)", },
{"id": "728","name": "Маяковского пл. (Дг)", },
{"id": "1704","name": "Медвежегорская (Дг)", },
{"id": "1042","name": "Медицинская (Мт)", },
{"id": "1203","name": "Медовая (С)", },
{"id": "729","name": "Международная (Пк)", },
{"id": "730","name": "Межпланетная (Пк)", },
{"id": "1617","name": "Мелиоративная (Дз)", },
{"id": "732","name": "Мелитопольская (Дг)", },
{"id": "733","name": "Менделеева (Ц-Г)", },
{"id": "735","name": "Мерецкова (Ц-Г)", },
{"id": "736","name": "Мерецкова пер. (Ц-Г)", },
{"id": "737","name": "Мериме (Ц-Г)", },
{"id": "1841","name": "Меркулова (С)", },
{"id": "738","name": "Меркурова (Дг)", },
{"id": "739","name": "Металлургов пр. (Мт)", },
{"id": "1705","name": "Механизаторов (Т)", },
{"id": "740","name": "Мехлиса (С)", },
{"id": "741","name": "Мечникова (Т)", },
{"id": "742","name": "Миасская (Ц-Г)", },
{"id": "699","name": "Миклухо-Маклая (Мт)", },
{"id": "743","name": "Милашенкова (Дг)", },
{"id": "744","name": "Милорадовская (Пк)", },
{"id": "745","name": "Минеральная (Т)", },
{"id": "746","name": "Минина (Ц-Г)", },
{"id": "747","name": "Минская (Т)", },
{"id": "749","name": "Мира проспект (Ц-Г,Дз,С)", },
{"id": "750","name": "Миргородская (Т)", },
{"id": "751","name": "Мировича (Т)", },
{"id": "752","name": "Мировская (Т)", },
{"id": "1851","name": "Мирослава Андрущука (И)", },
{"id": "754","name": "Мирошниченко (Т)", },
{"id": "755","name": "Митрофанова (Пк)", },
{"id": "650","name": "Михаила Курака (Мт)", },
{"id": "756","name": "Мицкевича (Т)", },
{"id": "1706","name": "Мичурина (Т)", },
{"id": "1840","name": "Мичурина (Ц-Г)", },
{"id": "1707","name": "Могилевская (И)", },
{"id": "757","name": "Можайского (Мт)", },
{"id": "758","name": "Моисеевская (Ц-Г)", },
{"id": "759","name": "Мокрицкого (Ц-Г)", },
{"id": "760","name": "Молдавская (Т)", },
{"id": "761","name": "Молдавская (Т)", },
{"id": "764","name": "Молодежная (Дг)", },
{"id": "1744","name": "Молодежная (Мт)", },
{"id": "762","name": "Молодогвардейцев (И)", },
{"id": "763","name": "Молодости (Ц-Г)", },
{"id": "1281","name": "Монастырская (Пк)", },
{"id": "765","name": "Монгольская (Ц-Г)", },
{"id": "766","name": "Монюшко (Ц-Г)", },
{"id": "768","name": "Мордовская (И)", },
{"id": "1557","name": "Москвина (Ц-Г)", },
{"id": "770","name": "Московская (Пк)", },
{"id": "771","name": "Моцарта (Дг)", },
{"id": "772","name": "Мраморная (Мт)", },
{"id": "773","name": "Мудреная (С)", },
{"id": "774","name": "Мудреновская (Дг)", },
{"id": "775","name": "Мудрости (Ц-Г)", },
{"id": "1708","name": "Мужества (Т)", },
{"id": "777","name": "Мукачева (Ц-Г)", },
{"id": "778","name": "Муравьева (Ц-Г)", },
{"id": "779","name": "Мурманская (Пк)", },
{"id": "780","name": "Муромская (Дг)", },
{"id": "781","name": "Мусоргского (Ж,Т)", },
{"id": "783","name": "Мухиной (Дг)", },
{"id": "784","name": "Мытищенская (Ц-Г)", },
{"id": "1558","name": "Набережная (Т)", },
{"id": "1838","name": "Набережная (Ц-Г)", },
{"id": "1612","name": "Навои (Ц-Г)", },
{"id": "1512","name": "Нагорная (Ц-Г)", },
{"id": "788","name": "Надежды (И)", },
{"id": "1522","name": "Найденко (И)", },
{"id": "789","name": "Найденко (И)", },
{"id": "790","name": "Наливайко (И)", },
{"id": "791","name": "Нансена (Пк)", },
{"id": "792","name": "Народная (Т)", },
{"id": "1259","name": "Народный (Т)", },
{"id": "1521","name": "Нартова (Ц-Г)", },
{"id": "1709","name": "Наставников (Ц-Г)", },
{"id": "793","name": "Научная (И)", },
{"id": "794","name": "Нахимова (Мт)", },
{"id": "1498","name": "Нахичеванская (Ц-Г)", },
{"id": "795","name": "Невельская (Дг)", },
{"id": "1404","name": "Недайводовская (Т)", },
{"id": "797","name": "Неделина (И)", },
{"id": "798","name": "Неждановой (Мт)", },
{"id": "1514","name": "Незабудок (Ц-Г)", },
{"id": "800","name": "Независим. Украины (Дг)", },
{"id": "0","name": "Неизвестная", },
{"id": "801","name": "Некрасова (Дг)", },
{"id": "520","name": "Немецкая (Ц-Г)", },
{"id": "785","name": "Немировича-Данченко (Мт)", },
{"id": "802","name": "Ненецкая (И)", },
{"id": "1523","name": "Неруды (Ц-Г)", },
{"id": "803","name": "Нерчинская (Дг)", },
{"id": "804","name": "Нестерова (Дг)", },
{"id": "1559","name": "Нижнетагильская (Ц-Г)", },
{"id": "805","name": "Нижняя (И)", },
{"id": "806","name": "Низами (Т)", },
{"id": "807","name": "Низовая (С)", },
{"id": "1710","name": "Никитина (Ц-Г)", },
{"id": "808","name": "Николаевская (Т)", },
{"id": "1496","name": "Николаевское шоссе (Ц-Г)", },
{"id": "1287","name": "Николая Зинчевского (Пк)", },
{"id": "529","name": "Николая Кацалапа (Дг)", },
{"id": "656","name": "Николая Кулиша (Ц-Г)", },
{"id": "1502","name": "Николая Ходича (Ц-Г)", },
{"id": "352","name": "Николокозельская (И)", },
{"id": "809","name": "Никопольское шоссе (Мт,Дг)", },
{"id": "810","name": "Нобелевская (С)", },
{"id": "811","name": "Новаторская (Дг)", },
{"id": "812","name": "Новая (И)", },
{"id": "813","name": "Новая Заря (Пк)", },
{"id": "814","name": "Новгородская (Мт)", },
{"id": "1818","name": "Новогодняя (И)", },
{"id": "786","name": "Новокриворожская (И)", },
{"id": "1526","name": "Новолозоват. пер. (Ц-Г)", },
{"id": "787","name": "Новомосковская (И)", },
{"id": "816","name": "Новоподольская (Мт)", },
{"id": "1283","name": "Новопокровская (Ц-Г)", },
{"id": "1711","name": "Новополесская (Т)", },
{"id": "1520","name": "Новороссийская (Ц-Г)", },
{"id": "1712","name": "Новоселовская (И)", },
{"id": "127","name": "Новоселовская (И)", },
{"id": "817","name": "Новосибирская (Т)", },
{"id": "815","name": "Новостроек (Пк)", },
{"id": "818","name": "Новошахтинская (Пк)", },
{"id": "820","name": "Норильская (Т)", },
{"id": "1497","name": "Ньютона (Ц-Г)", },
{"id": "1606","name": "Областная (Ж)", },
{"id": "823","name": "Обнорского (Дг)", },
{"id": "1561","name": "Обогатительная (И)", },
{"id": "537","name": "Обогатительная (И)", },
{"id": "1374","name": "Оболоновская (Пк)", },
{"id": "824","name": "Образцовый пер. (Дг)", },
{"id": "825","name": "Обручева (И)", },
{"id": "826","name": "Обсерваторная (Т)", },
{"id": "1713","name": "Обуховой (Ц-Г)", },
{"id": "827","name": "Обыкновенный пер. (Т)", },
{"id": "1819","name": "Овощеводов (И)", },
{"id": "1820","name": "Овсиенко (Т)", },
{"id": "829","name": "Овчаренко (Т)", },
{"id": "830","name": "Огарева (С)", },
{"id": "831","name": "Огинского (Т)", },
{"id": "832","name": "Огневая (Т)", },
{"id": "833","name": "Огородная (Пк)", },
{"id": "834","name": "Одесская (С)", },
{"id": "835","name": "Одоевского (Ц-Г)", },
{"id": "1528","name": "Оздоровит. городок (Пк)", },
{"id": "1821","name": "Озерная (И)", },
{"id": "836","name": "Озерная (С)", },
{"id": "837","name": "Ойстраха (Ц-Г)", },
{"id": "838","name": "Океанская (Т)", },
{"id": "839","name": "Оконный пер. (Т)", },
{"id": "840","name": "Окружная (Ц-Г)", },
{"id": "842","name": "Олега Кошевого (Т)", },
{"id": "822","name": "Олега Куприянца (И)", },
{"id": "1850","name": "Олега Рычкова (И)", },
{"id": "559","name": "Олексы Горняка (Мт)", },
{"id": "845","name": "Олимпийская (Т)", },
{"id": "846","name": "Олонецкая (С)", },
{"id": "847","name": "Ольховская (Дг)", },
{"id": "37","name": "Ольховый (Дг)", },
{"id": "849","name": "Омская (Пк)", },
{"id": "850","name": "Онежская (Т)", },
{"id": "851","name": "Опарина пер. (Т)", },
{"id": "852","name": "Орбели (Дг)", },
{"id": "140","name": "Орельская (Ц-Г)", },
{"id": "854","name": "Оренбургская (Т)", },
{"id": "855","name": "Ореховская (Т)", },
{"id": "734","name": "Орлиное гнездо (Ц-Г)", },
{"id": "856","name": "Орловская (Мт)", },
{"id": "1714","name": "Орская (Т)", },
{"id": "857","name": "Оршинская (Т)", },
{"id": "1565","name": "Освитянская (Пк)", },
{"id": "858","name": "Освобождения пл. (Ц-Г)", },
{"id": "859","name": "Осенняя (Т)", },
{"id": "241","name": "Осенняя (Ц-Г)", },
{"id": "1715","name": "Осетинская (Т)", },
{"id": "860","name": "Осипенко (Ц-Г)", },
{"id": "821","name": "Остапа Вишни (И)", },
{"id": "861","name": "Островная (Т)", },
{"id": "863","name": "Остроградская (Т)", },
{"id": "684","name": "Отводовская (И)", },
{"id": "864","name": "Отечественная (Мт)", },
{"id": "867","name": "Отчизны (Мт)", },
{"id": "868","name": "Офицерская (Ц-Г)", },
{"id": "869","name": "Охотская (С)", },
{"id": "870","name": "Очаковская (Мт)", },
{"id": "104","name": "Павла Глазового (С)", },
{"id": "335","name": "Павла Чубинского (И)", },
{"id": "873","name": "Павленко (Ц-Г)", },
{"id": "875","name": "Павлоградская (Дг)", },
{"id": "876","name": "Павлодарского (Т)", },
{"id": "877","name": "Паганини (Ц-Г)", },
{"id": "1394","name": "Паланкова (Т)", },
{"id": "929","name": "Палехская (Т)", },
{"id": "1716","name": "Палладина (Т)", },
{"id": "878","name": "Памяти Героев (Мт)", },
{"id": "871","name": "Панаса Мирного (И)", },
{"id": "420","name": "Панаса Саксаганского (С)", },
{"id": "36","name": "Панаса Феденко (Пк)", },
{"id": "880","name": "Панкова (И)", },
{"id": "882","name": "Панферова (И)", },
{"id": "881","name": "Панфилова (Мт)", },
{"id": "884","name": "Парковая (С)", },
{"id": "885","name": "Паровозная (Дг)", },
{"id": "886","name": "Партизанская (Мт)", },
{"id": "1822","name": "Пархоменко (С)", },
{"id": "887","name": "Пастера (Дг)", },
{"id": "888","name": "Патона (Ц-Г)", },
{"id": "1718","name": "Паторжинского (Т)", },
{"id": "889","name": "Паустовского (Т)", },
{"id": "1511","name": "Пензенская (Дг)", },
{"id": "891","name": "Первомайская (Ц-Г)", },
{"id": "892","name": "Первоуральская (Ц-Г)", },
{"id": "1719","name": "Переездный пер. (Т)", },
{"id": "894","name": "Перещепинская (Пк)", },
{"id": "895","name": "Переяславская (И)", },
{"id": "896","name": "Пермская (Ц-Г)", },
{"id": "897","name": "Перова (С,Пк)", },
{"id": "898","name": "Песочная (Мт)", },
{"id": "899","name": "Пестеля (Ц-Г)", },
{"id": "900","name": "Петлякова пл. (Т)", },
{"id": "150","name": "Петра Дорошенко (Дг)", },
{"id": "1293","name": "Петра Калнишевского (Ц-Г)", },
{"id": "901","name": "Петриковская (Дг)", },
{"id": "904","name": "Пехотинская (Ц-Г)", },
{"id": "905","name": "Печерская (Ц-Г)", },
{"id": "1826","name": "Пикассо (С)", },
{"id": "1720","name": "Пимоненко (Т)", },
{"id": "1721","name": "Пинская (Ж)", },
{"id": "1722","name": "Пиренейская (Ц-Г)", },
{"id": "908","name": "Пирогова (Ц-Г)", },
{"id": "1723","name": "Пирятинская (Пк)", },
{"id": "909","name": "Писарева (Пк)", },
{"id": "1724","name": "Писаржевского (Ж)", },
{"id": "910","name": "Писемского (Пк)", },
{"id": "912","name": "Пичугина (С)", },
{"id": "913","name": "Плавильщиков (Пк)", },
{"id": "914","name": "Планетарная (Пк)", },
{"id": "915","name": "Плановая (Дг)", },
{"id": "1616","name": "Племяшова (И)", },
{"id": "916","name": "Плеханова (Ц-Г)", },
{"id": "917","name": "Пловдивская (Дг)", },
{"id": "1725","name": "Пляжная (Т)", },
{"id": "1823","name": "Победителей (И)", },
{"id": "918","name": "Победы пр. (И)", },
{"id": "1726","name": "Пограничников (Ж)", },
{"id": "1727","name": "Поддубного (Ц-Г)", },
{"id": "921","name": "Подлепы (И)", },
{"id": "922","name": "Подольская (Пк)", },
{"id": "1459","name": "Подснежниковая (Т)", },
{"id": "923","name": "Подстепная (Ц-Г)", },
{"id": "924","name": "Пожарского (Ц-Г)", },
{"id": "389","name": "Позитивная (Ц-Г)", },
{"id": "920","name": "Покровская (С)", },
{"id": "925","name": "Покрышева (Т)", },
{"id": "926","name": "Покрышкина (Ц-Г)", },
{"id": "1728","name": "Полевая (Мт)", },
{"id": "927","name": "Поленова (Т)", },
{"id": "928","name": "Полесская (Т)", },
{"id": "1717","name": "Полетаева пер. (Ж)", },
{"id": "930","name": "Ползунова (Т)", },
{"id": "931","name": "Политехническая (Ц-Г)", },
{"id": "1482","name": "Полковника Морозенко (Ц-Г)", },
{"id": "902","name": "Половецкая (И)", },
{"id": "932","name": "Полтавская (Мт)", },
{"id": "1729","name": "Полухина пер. (Ц-Г)", },
{"id": "933","name": "Польская (Ц-Г)", },
{"id": "934","name": "Поля (Пк)", },
{"id": "935","name": "Полярная (Т)", },
{"id": "936","name": "Поперечная (С)", },
{"id": "937","name": "Попова (Дг)", },
{"id": "938","name": "Поповича (Дг)", },
{"id": "939","name": "Поселковая (Т)", },
{"id": "940","name": "Постоя (Ц-Г)", },
{"id": "942","name": "Потеряева (Дг)", },
{"id": "943","name": "Почтовая (И)", },
{"id": "492","name": "Почтовый (Ц-Г)", },
{"id": "945","name": "Правобережная (И)", },
{"id": "946","name": "Пражская (Пк)", },
{"id": "947","name": "Праздничная (Ц-Г)", },
{"id": "948","name": "Пржевальского (Ц-Г)", },
{"id": "146","name": "Приветная (Дг)", },
{"id": "1436","name": "Привлекательная (Т)", },
{"id": "949","name": "Привокзальная (Дг)", },
{"id": "1238","name": "Привокзальный (Дг)", },
{"id": "950","name": "Приволжская (Т)", },
{"id": "951","name": "Привольная (Пк)", },
{"id": "952","name": "Пригородная (Пк)", },
{"id": "1562","name": "Призовая (Т)", },
{"id": "954","name": "Приозерная (Дг)", },
{"id": "251","name": "Приречная (Пк)", },
{"id": "955","name": "Пристанционная (Т)", },
{"id": "956","name": "Пришвина (С)", },
{"id": "959","name": "Промышленная (Дг)", },
{"id": "767","name": "Прорезная (Ц-Г)", },
{"id": "960","name": "Проскуровская (Дг)", },
{"id": "961","name": "Профсоюзная (Мт)", },
{"id": "962","name": "Прохладная (И)", },
{"id": "1337","name": "Проходная (И)", },
{"id": "1730","name": "Прусса (И)", },
{"id": "1563","name": "Псковская (Ц-Г)", },
{"id": "963","name": "Пугачева (Ц-Г)", },
{"id": "1399","name": "Пужмерки (Т)", },
{"id": "964","name": "Пулковский пер. (Ц-Г)", },
{"id": "965","name": "Пурина (Дг)", },
{"id": "966","name": "Путейная (Дг)", },
{"id": "1825","name": "Путейный пер.(Дг)", },
{"id": "968","name": "Путейцев (Дг)", },
{"id": "969","name": "Путиловский пер. (Ц-Г)", },
{"id": "970","name": "Пухова (Т)", },
{"id": "971","name": "Пуччини (Ц-Г)", },
{"id": "295","name": "Пушечная (Пк)", },
{"id": "972","name": "Пушкина (Ц-Г)", },
{"id": "973","name": "Пхеньянская (Ц-Г)", },
{"id": "1564","name": "Пятигорская (Пк)", },
{"id": "974","name": "Пятихатская (Пк)", },
{"id": "1731","name": "Пятницкого (И)", },
{"id": "977","name": "Рабочая (И)", },
{"id": "978","name": "Радищева (Пк)", },
{"id": "1517","name": "Радости (Ц-Г)", },
{"id": "1807","name": "Радужная (И)", },
{"id": "979","name": "Раевского (Т)", },
{"id": "980","name": "Разенкова (Дг)", },
{"id": "1827","name": "Разенкова (Т)", },
{"id": "1540","name": "Разина (Ц-Г)", },
{"id": "1515","name": "Ракетная (Ц-Г)", },
{"id": "981","name": "Рафаэля (Пк)", },
{"id": "1504","name": "Рахманинова (Ц-Г)", },
{"id": "588","name": "Рахмановская (Ц-Г)", },
{"id": "984","name": "Рейнская (Дг)", },
{"id": "250","name": "Рекордная (Пк)", },
{"id": "1566","name": "Рембрандта (Ц-Г)", },
{"id": "985","name": "Ремесленная (Т)", },
{"id": "1519","name": "Репина (Ц-Г)", },
{"id": "1567","name": "Рериха (Ц-Г)", },
{"id": "986","name": "Речная (Т)", },
{"id": "988","name": "Рижская (Т)", },
{"id": "989","name": "Ровенская (Т)", },
{"id": "990","name": "Родины (Дг)", },
{"id": "390","name": "Родниковый пер. (Ц-Г)", },
{"id": "976","name": "Роз (Дг)", },
{"id": "991","name": "Рокоссовского (С,Дз)", },
{"id": "992","name": "Ромена Роллана (Дг)", },
{"id": "4","name": "Ромская (Дг)", },
{"id": "1568","name": "Российская (Ц-Г)", },
{"id": "1569","name": "Россини (Ц-Г)", },
{"id": "993","name": "Ростовская (Дг)", },
{"id": "1570","name": "Рубежная (Ц-Г)", },
{"id": "994","name": "Рубенса (Дг)", },
{"id": "995","name": "Рублева (С)", },
{"id": "996","name": "Руданского (Т)", },
{"id": "997","name": "Руденко (Пк)", },
{"id": "998","name": "Рудная (И)", },
{"id": "1609","name": "Рудничная (Ц-Г)", },
{"id": "1732","name": "Русская (Ц-Г)", },
{"id": "1416","name": "Руставели (Пк)", },
{"id": "999","name": "Рыбаков (Т)", },
{"id": "1525","name": "Рыбалко (Ц-Г)", },
{"id": "1376","name": "Рыбальская (Пк)", },
{"id": "169","name": "Рыбасовская (Пк)", },
{"id": "1000","name": "Рыбинская (Т)", },
{"id": "1733","name": "Рыжова (Ж)", },
{"id": "1556","name": "Рылеева (Ц-Г)", },
{"id": "1001","name": "Рыльского (Дг)", },
{"id": "1002","name": "Рябиновая (Т)", },
{"id": "1794","name": "Рябиновый пер. (Т)", },
{"id": "1004","name": "Рязанская (Мт)", },
{"id": "1008","name": "Савицкого (И)", },
{"id": "1009","name": "Саврасова (Пк)", },
{"id": "1010","name": "Сагайдачного (С,Пк)", },
{"id": "1011","name": "Садовая (И)", },
{"id": "1793","name": "Садовая (Пк)", },
{"id": "1620","name": "Садовая (Т)", },
{"id": "1790","name": "Садовая (Ц-Г)", },
{"id": "1792","name": "Садовая(искл.) (Ж)", },
{"id": "1012","name": "Садовского (Мт)", },
{"id": "1619","name": "Садовый пер. (Ц-Г)", },
{"id": "1013","name": "Сакко и Ванцетти (Ц-Г)", },
{"id": "1734","name": "Саксаганская площадь (Т)", },
{"id": "1015","name": "Салавата Юлаева (Т)", },
{"id": "1016","name": "Саласюка (Пк)", },
{"id": "1006","name": "Салтыкова-Щедрина (Мт)", },
{"id": "711","name": "Салтыковская (И)", },
{"id": "1017","name": "Сальская (Мт)", },
{"id": "1020","name": "Салютная (И)", },
{"id": "1021","name": "Самаркандская (Пк)", },
{"id": "1022","name": "Самарская (Дг)", },
{"id": "1024","name": "Самотечная (Т)", },
{"id": "1025","name": "Саранская (Ц-Г)", },
{"id": "1026","name": "Саратовская (Мт)", },
{"id": "1126","name": "Сарматская (Ц-Г)", },
{"id": "1572","name": "Сахалинская (Т)", },
{"id": "1027","name": "Сахарова (Дг)", },
{"id": "1028","name": "Саянская (Ц-Г)", },
{"id": "1735","name": "Светлая (Т)", },
{"id": "1030","name": "Светлова (С)", },
{"id": "1031","name": "Светлогорская (С)", },
{"id": "1736","name": "Свидерского (Ц-Г)", },
{"id": "1033","name": "Свободы (Т)", },
{"id": "1034","name": "Связи (Пк)", },
{"id": "661","name": "Свято-Николаевская (Ц-Г)", },
{"id": "1003","name": "Святогеоргиевская (Мт,С)", },
{"id": "1035","name": "Севанская (Пк)", },
{"id": "1036","name": "Севастопольская (С)", },
{"id": "22","name": "Северная (Т)", },
{"id": "1037","name": "Северный пер. (Ц-Г)", },
{"id": "1038","name": "Северодвинская (Дг)", },
{"id": "1039","name": "Седнева пр. (И)", },
{"id": "1040","name": "Седова (Т)", },
{"id": "605","name": "Сельская (Мт)", },
{"id": "1041","name": "Сельскохозяйственная (Дг)", },
{"id": "1574","name": "Семипалатинская (Т)", },
{"id": "1738","name": "Семипалатинский пер. (Т)", },
{"id": "1043","name": "Семьи Демиды (И)", },
{"id": "10","name": "Семьи Добровольских (И)", },
{"id": "1432","name": "Семьи Коваленко (Пк)", },
{"id": "769","name": "Семьи Харченко (Т)", },
{"id": "1392","name": "Семьи Шильманов (Пк)", },
{"id": "1739","name": "Сенная (Ж)", },
{"id": "1044","name": "Сентябрьская (И)", },
{"id": "1045","name": "Серафимовича (Дг)", },
{"id": "1046","name": "Сервантеса (Дг)", },
{"id": "1740","name": "Сергева-Ценского (Ц-Г)", },
{"id": "1852","name": "Сергея Заики (И)", },
{"id": "3","name": "Сергея Колачевского (Т)", },
{"id": "207","name": "Сергея Параджанова (Дг)", },
{"id": "1050","name": "Серегина (Пк)", },
{"id": "1048","name": "Серова (Ц-Г)", },
{"id": "1049","name": "Серпуховская (С)", },
{"id": "1051","name": "Сестрорецкая (Т)", },
{"id": "292","name": "Сечевая (С)", },
{"id": "7","name": "Сечевых Стрельцов (Дг)", },
{"id": "1052","name": "Сеченова (Пк)", },
{"id": "1053","name": "Сибирская (Пк)", },
{"id": "1054","name": "Сивашский спуск (Т)", },
{"id": "1741","name": "Сиверский пер. (Дг)", },
{"id": "1056","name": "Силикатная (Ц-Г)", },
{"id": "1057","name": "Симбирцева (Пк)", },
{"id": "1058","name": "Симонова (Дг)", },
{"id": "1059","name": "Симферопольская (Мт)", },
{"id": "1060","name": "Синявина (Ц-Г)", },
{"id": "103","name": "Сиреневая (И)", },
{"id": "1420","name": "Скалеватая (И)", },
{"id": "1061","name": "Скандинавская (С)", },
{"id": "62","name": "Скифская (Ц-Г)", },
{"id": "1062","name": "Скляра (С)", },
{"id": "1063","name": "Сковороды (Пк)", },
{"id": "1064","name": "Скочинского (Мт)", },
{"id": "1065","name": "Скреперная (Пк)", },
{"id": "1067","name": "Скрябина (Т)", },
{"id": "1828","name": "Славенская (Дг)", },
{"id": "1068","name": "Славы пер. (Ц-Г)", },
{"id": "1069","name": "Славянская (Дг)", },
{"id": "1742","name": "Слепнева (С)", },
{"id": "1830","name": "Смирнова (Т)", },
{"id": "1072","name": "Смоленская (Т)", },
{"id": "1074","name": "Снегиревская (Т)", },
{"id": "1743","name": "Собинова (Ц-Г)", },
{"id": "1829","name": "Соборная (С)", },
{"id": "581","name": "Соборности (Мт,Дг)", },
{"id": "1078","name": "Содружества (С)", },
{"id": "1079","name": "Соколиная (Пк)", },
{"id": "879","name": "Соколовская (Пк)", },
{"id": "1081","name": "Солдатская (Ц-Г)", },
{"id": "1082","name": "Солидарности (Ц-Г)", },
{"id": "1083","name": "Соликамская (Пк)", },
{"id": "1084","name": "Солнечная (И)", },
{"id": "1085","name": "Солнечный м-н (С)", },
{"id": "1745","name": "Солнцева пер. (Дз)", },
{"id": "1086","name": "Соловьиная (Мт)", },
{"id": "617","name": "Соломии Крушельницкой (С)", },
{"id": "1087","name": "Сормовская (Дг)", },
{"id": "1088","name": "Сорочинская (Мт)", },
{"id": "1746","name": "Сосновый пер. (Ж)", },
{"id": "1747","name": "Сосюры (Т)", },
{"id": "1089","name": "Софиевская (Дг)", },
{"id": "1005","name": "Софьи Ковалевской (Мт)", },
{"id": "1090","name": "Софьи Перовской (С)", },
{"id": "1092","name": "Сочинская (Т)", },
{"id": "1093","name": "Спандаряна (Дг)", },
{"id": "1094","name": "Спартака (Т)", },
{"id": "1095","name": "Спасская (С)", },
{"id": "1096","name": "Спокойная (С)", },
{"id": "1097","name": "Спортивная (И)", },
{"id": "1216","name": "Ставковая (Пк)", },
{"id": "1530","name": "Ставропольская (Т)", },
{"id": "1099","name": "Стадионная (Пк)", },
{"id": "1100","name": "Сталеваров (Мт)", },
{"id": "1168","name": "Станислава Конткевича (Пк)", },
{"id": "1101","name": "Станиславского (С)", },
{"id": "1748","name": "Станционный пер. (Т)", },
{"id": "1845","name": "Станция Колонка (Ц-Г)", },
{"id": "1103","name": "Старицкого (Мт)", },
{"id": "1104","name": "Старобешевская (Т)", },
{"id": "1229","name": "Старовокзальная (Ц-Г)", },
{"id": "1494","name": "Старогородская (Ц-Г)", },
{"id": "1080","name": "Староингулецкая (И)", },
{"id": "1055","name": "Староярмарковая (Ц-Г)", },
{"id": "1106","name": "Стасова (Пк)", },
{"id": "1107","name": "Сташкова (Ц-Г)", },
{"id": "1749","name": "Стеклова (Мт)", },
{"id": "1108","name": "Стендаля (Дг)", },
{"id": "982","name": "Степана Тильги (Мт,Дг)", },
{"id": "1109","name": "Степная (Т)", },
{"id": "360","name": "Степной Дивизии (Ц-Г)", },
{"id": "1110","name": "Стерлитамакская (Ц-Г)", },
{"id": "1111","name": "Стефаника (Мт)", },
{"id": "1112","name": "Стеценко (Мт)", },
{"id": "1113","name": "Столичная (Дг)", },
{"id": "1114","name": "Стрельникова (Мт)", },
{"id": "1115","name": "Стрельцова (Ц-Г)", },
{"id": "1116","name": "Строителей (И)", },
{"id": "1798","name": "Строительная (Дг)", },
{"id": "1831","name": "Строительная (И)", },
{"id": "1117","name": "Студенческая (Ц-Г)", },
{"id": "1119","name": "Субботняя (Ц-Г)", },
{"id": "1120","name": "Суворова (Мт)", },
{"id": "1121","name": "Суздальская (Пк)", },
{"id": "1122","name": "Сулеймана Стальского (Пк)", },
{"id": "1123","name": "Сумгаитская (Ц-Г)", },
{"id": "1124","name": "Сумская (Т)", },
{"id": "1125","name": "Сурикова (Т)", },
{"id": "1127","name": "Сухомлинского (И)", },
{"id": "1128","name": "Сухумская (Дг)", },
{"id": "1750","name": "Сучанская (Т)", },
{"id": "599","name": "Счастливая (Пк)", },
{"id": "1129","name": "Сызранская (Дг)", },
{"id": "1014","name": "Сыктывкарская (Ц-Г)", },
{"id": "1131","name": "Таганрогская (С)", },
{"id": "1132","name": "Таганская (И)", },
{"id": "1133","name": "Тагора (Т)", },
{"id": "1134","name": "Таджикская (И)", },
{"id": "1751","name": "Таежная (Ж)", },
{"id": "1135","name": "Таежная (Мт)", },
{"id": "183","name": "Таисии Буряченко (Т)", },
{"id": "1136","name": "Таймырская (Ц-Г)", },
{"id": "1137","name": "Талалихина (Пк)", },
{"id": "1138","name": "Таллинская (Т)", },
{"id": "1139","name": "Таманская (Ц-Г)", },
{"id": "1167","name": "Тамбовская (Дг)", },
{"id": "1802","name": "Танеева (Т)", },
{"id": "1140","name": "Танеева пер. (Т)", },
{"id": "1141","name": "Танкистов (Ц-Г)", },
{"id": "944","name": "Тарапаковская (Ц-Г)", },
{"id": "1142","name": "Тарасовой (Мт)", },
{"id": "1143","name": "Татарская (Ц-Г)", },
{"id": "1147","name": "Татьяны Вороновой (Мт)", },
{"id": "1752","name": "Ташкентская (Ж)", },
{"id": "1144","name": "Тбилисская (Мт)", },
{"id": "1145","name": "Твардовского (И)", },
{"id": "1146","name": "Театральная (Т)", },
{"id": "1148","name": "Телевизионная (Дг)", },
{"id": "1150","name": "Темиртаусская (Дг)", },
{"id": "1152","name": "Тенистая (Ц-Г)", },
{"id": "1153","name": "Теплая (Ц-Г)", },
{"id": "1154","name": "Тереховского (Ц-Г)", },
{"id": "1155","name": "Терновская (Т)", },
{"id": "1753","name": "Тернопольская (Т)", },
{"id": "1156","name": "Тесленко (С)", },
{"id": "1157","name": "Техническая (С)", },
{"id": "1151","name": "Тимирязева (Т)", },
{"id": "1158","name": "Тимошенко (Ц-Г)", },
{"id": "1159","name": "Типанова (Дг)", },
{"id": "1755","name": "Тираспольская (С)", },
{"id": "1600","name": "Тихая (И)", },
{"id": "1160","name": "Тихвинская (Пк)", },
{"id": "1161","name": "Тихоокеанская (Мт)", },
{"id": "1577","name": "Тихорецкая (Т)", },
{"id": "1162","name": "Тобольская (Т)", },
{"id": "1164","name": "Толбухина (Пк)", },
{"id": "1165","name": "Толстого (Ц-Г)", },
{"id": "1608","name": "Толстого пл. (Т)", },
{"id": "1169","name": "Тополиная (Ц-Г)", },
{"id": "1171","name": "Трактористов (Пк)", },
{"id": "1756","name": "Трамвайная (Дз)", },
{"id": "862","name": "Трамвайная (Мт)", },
{"id": "1172","name": "Транзитная (Дг)", },
{"id": "1173","name": "Транспортная (Дг)", },
{"id": "1797","name": "Транспортный пер. (Дз)", },
{"id": "1174","name": "Тренева (Т)", },
{"id": "1757","name": "Третий километр (Дг)", },
{"id": "1175","name": "Третьякова пер. (Пк)", },
{"id": "1176","name": "Троицкая (Т)", },
{"id": "1177","name": "Тропинина пер. (Ц-Г)", },
{"id": "1178","name": "Трубецкого (Ц-Г)", },
{"id": "1179","name": "Труда (Т)", },
{"id": "1858","name": "Труда пл. (С)", },
{"id": "1181","name": "Трудовой Славы (Ц-Г)", },
{"id": "1182","name": "Трудовых резервов (Ц-Г)", },
{"id": "1180","name": "Тружеников (Мт)", },
{"id": "1183","name": "Труща пер. (Ц-Г)", },
{"id": "1184","name": "Тувинская (Ц-Г)", },
{"id": "1185","name": "Тульская (Дг)", },
{"id": "1758","name": "Туманяна (Ж)", },
{"id": "1186","name": "Тунгусская (Пк)", },
{"id": "1187","name": "Тупиковая (Дг)", },
{"id": "1188","name": "Туполева (Ц-Г)", },
{"id": "1759","name": "Тургайская (И)", },
{"id": "1189","name": "Тургенева (Ц-Г)", },
{"id": "1190","name": "Туринская (И)", },
{"id": "1467","name": "Туристическая (Ц-Г)", },
{"id": "1191","name": "Туркенича (Т)", },
{"id": "1192","name": "Туркменская (Мт)", },
{"id": "1193","name": "Турнирная (Ц-Г)", },
{"id": "1555","name": "Турчаниновой (Ц-Г)", },
{"id": "1196","name": "Тычины (Т)", },
{"id": "1197","name": "Тюленина (Пк)", },
{"id": "1198","name": "Тюльпанов (Ц-Г)", },
{"id": "1199","name": "Тюменская (Дг)", },
{"id": "1200","name": "Тютчева (Ц-Г)", },
{"id": "1201","name": "Тютюнника (Ц-Г)", },
{"id": "1588","name": "Тяговая подстанция (Дг)", },
{"id": "1202","name": "Тянь-шанская (Ц-Г)", },
{"id": "1579","name": "Удальцова (Дг)", },
{"id": "1205","name": "Ударная (Пк)", },
{"id": "1208","name": "Удегейская (И)", },
{"id": "1206","name": "Удельный пер. (Ц-Г)", },
{"id": "1207","name": "Удмуртская (Ц-Г)", },
{"id": "1209","name": "Ужгородская (Пк)", },
{"id": "1210","name": "Узбекская (Дг)", },
{"id": "1211","name": "Узловая (Пк)", },
{"id": "1212","name": "Украинская (Ц-Г)", },
{"id": "1213","name": "Улан-Батора (Ц-Г)", },
{"id": "1214","name": "Улан-удинский пер. (Ц-Г)", },
{"id": "1215","name": "Улитина (Дг)", },
{"id": "1810","name": "улица отсутствует", },
{"id": "1581","name": "Ульриха (Дг)", },
{"id": "1760","name": "Ульяновых (Дг)", },
{"id": "1218","name": "Уманская (Т)", },
{"id": "1219","name": "Умельцев (Ц-Г)", },
{"id": "1221","name": "Умова (Дг)", },
{"id": "1222","name": "Унгенская (Ц-Г)", },
{"id": "1223","name": "Универсальная (Т)", },
{"id": "1224","name": "Университетская (Пк)", },
{"id": "1225","name": "Упита (С)", },
{"id": "1226","name": "Уральская (И)", },
{"id": "1228","name": "Урбанского (Дг)", },
{"id": "1230","name": "Урожайная (Ц-Г)", },
{"id": "1806","name": "Урожайная Рах.(Ц-Г)", },
{"id": "1231","name": "Урюпинская (Т)", },
{"id": "1232","name": "Усика (Ц-Г)", },
{"id": "1650","name": "Условная (Дг)", },
{"id": "1233","name": "Успенского (Мт)", },
{"id": "1234","name": "Уссурийская (Ц-Г)", },
{"id": "1235","name": "Уставная (Ц-Г)", },
{"id": "1236","name": "Устияновича (Т)", },
{"id": "1237","name": "Усть-каменогорская (Ц-Г)", },
{"id": "1239","name": "Уткина (Мт)", },
{"id": "1240","name": "Уточкина (Т)", },
{"id": "1241","name": "Уфимская (Пк)", },
{"id": "1242","name": "Уфимцева (Т)", },
{"id": "1243","name": "Ухтомского (Т)", },
{"id": "1244","name": "Учителей (Ц-Г)", },
{"id": "1832","name": "Ушакова (Ц-Г)", },
{"id": "1246","name": "Ушинского (Т)", },
{"id": "95","name": "Уютная (Дг)", },
{"id": "1761","name": "Фабричная (Пк)", },
{"id": "1248","name": "Фаворского (Т)", },
{"id": "1250","name": "Факельный пер. (Т)", },
{"id": "1251","name": "Фальковского (Пк)", },
{"id": "1252","name": "Фарадея (Пк)", },
{"id": "1253","name": "Фарфоровая (Ц-Г)", },
{"id": "1255","name": "Фастовская (Т)", },
{"id": "698","name": "Февральская (Пк)", },
{"id": "1848","name": "Федора Дацко (И)", },
{"id": "1257","name": "Федорова (Пк)", },
{"id": "1258","name": "Федоровского (С)", },
{"id": "1762","name": "Федотова (Пк)", },
{"id": "1763","name": "Федченко (Дз)", },
{"id": "1261","name": "Федьковича (Т)", },
{"id": "1262","name": "Феликса Кона (Т)", },
{"id": "1264","name": "Феодосийская (Пк)", },
{"id": "1265","name": "Ферганская (Мт)", },
{"id": "1764","name": "Фестивальная (И)", },
{"id": "1765","name": "Фета (Т)", },
{"id": "1267","name": "Фиалковая (И)", },
{"id": "1268","name": "Фигнер (Пк)", },
{"id": "1603","name": "Физкультурная (И)", },
{"id": "1269","name": "Филатова (С)", },
{"id": "486","name": "Филиппа Орлика (Мт)", },
{"id": "1270","name": "Филиппова (И)", },
{"id": "1766","name": "Филипповича (Ц-Г)", },
{"id": "1271","name": "Филянского (Т)", },
{"id": "1272","name": "Финская (Ц-Г)", },
{"id": "1273","name": "Фисташковая (Ц-Г)", },
{"id": "1274","name": "Флеровского (Т)", },
{"id": "1275","name": "Флобера (И)", },
{"id": "1276","name": "Флоксов пер. (Ц-Г)", },
{"id": "1767","name": "Флотская (Т)", },
{"id": "1768","name": "Фомина (Ж)", },
{"id": "1277","name": "Фонвизина (Т)", },
{"id": "1769","name": "Фосфорный пер. (Ц-Г)", },
{"id": "1279","name": "Франса (Ц-Г)", },
{"id": "561","name": "Французкая (Пк)", },
{"id": "1278","name": "Фроленко (Ц-Г)", },
{"id": "1280","name": "Фруктовая (Ц-Г)", },
{"id": "1770","name": "Фрунзенская (Ц-Г)", },
{"id": "1282","name": "Фуко (Ц-Г)", },
{"id": "1284","name": "Футбольная (Дг)", },
{"id": "1285","name": "Фучика (Ц-Г)", },
{"id": "1286","name": "Фучика пер.(Ц-Г)", },
{"id": "1288","name": "Хабаровская (Мт)", },
{"id": "1289","name": "Хайфонская (Ц-Г)", },
{"id": "1290","name": "Хакасская (Т)", },
{"id": "1291","name": "Халтурина (Ц-Г)", },
{"id": "1292","name": "Ханойская (Пк)", },
{"id": "953","name": "Харина (Т)", },
{"id": "1294","name": "Харцызская (С)", },
{"id": "1295","name": "Харьковская (С)", },
{"id": "1296","name": "Хасанская (Ц-Г)", },
{"id": "1298","name": "Хвойная (Ц-Г)", },
{"id": "1299","name": "Херсонская (Ц-Г)", },
{"id": "1300","name": "Хетагурова (С)", },
{"id": "1301","name": "Хибинская (Ц-Г)", },
{"id": "1302","name": "Химиков (Мт)", },
{"id": "1007","name": "Хлебная (Мт)", },
{"id": "1303","name": "Хлеборобная (Ц-Г)", },
{"id": "1304","name": "Хлопуши (Дг)", },
{"id": "1305","name": "Хмелева (Ц-Г)", },
{"id": "1771","name": "Хоккейная (И)", },
{"id": "1306","name": "Холмогорская (Пк)", },
{"id": "652","name": "Холоднояровская (Пк)", },
{"id": "1307","name": "Хорватская (Ц-Г)", },
{"id": "1308","name": "Хорезмская (Дг)", },
{"id": "1833","name": "Хорезмская (Пк)", },
{"id": "1309","name": "Хороводная (Мт)", },
{"id": "1310","name": "Хорогская (Пк)", },
{"id": "604","name": "Хортицкая (Дг)", },
{"id": "1311","name": "Хостинская (Ц-Г)", },
{"id": "1312","name": "Хохломская (Дг)", },
{"id": "1314","name": "Хрустальная (Дг)", },
{"id": "466","name": "Художественная (Мт)", },
{"id": "872","name": "Художников (Дг)", },
{"id": "1260","name": "Хуторская (И)", },
{"id": "1315","name": "Цандера (Т)", },
{"id": "1316","name": "Царичанская (Ц-Г)", },
{"id": "1317","name": "Цвейга (Ц-Г)", },
{"id": "1789","name": "Цветаевой (Ц-Г)", },
{"id": "1319","name": "Цветная (Ц-Г)", },
{"id": "1320","name": "Цветочная (Т)", },
{"id": "629","name": "Цветочная (Т)", },
{"id": "717","name": "Цветущий (Т)", },
{"id": "1321","name": "Целинная (Т)", },
{"id": "1772","name": "Целиноградская (И)", },
{"id": "1322","name": "Цементная (Ц-Г)", },
{"id": "1323","name": "Цементников (Дг)", },
{"id": "1324","name": "Центральная (Т)", },
{"id": "1325","name": "Центральный пер.(Т)", },
{"id": "1326","name": "Церетели (И)", },
{"id": "504","name": "Церковная (Ц-Г)", },
{"id": "1327","name": "Цеховая (Ц-Г)", },
{"id": "1773","name": "Цимлянская (Мт)", },
{"id": "1328","name": "Циолковского (Мт)", },
{"id": "1329","name": "Цитрусовая (Дг)", },
{"id": "1774","name": "Цулукидзе (Ц-Г)", },
{"id": "1332","name": "Чаадаева (Мт)", },
{"id": "1333","name": "Чавдара (И)", },
{"id": "1334","name": "Чаек (И)", },
{"id": "1335","name": "Чайковского (Мт)", },
{"id": "1336","name": "Чайная (Пк)", },
{"id": "1338","name": "Чапека (И)", },
{"id": "1339","name": "Чаплыгина (Дг)", },
{"id": "586","name": "Чаривна (Т)", },
{"id": "1834","name": "Чарского (Мт)", },
{"id": "1340","name": "Чебоксарская (Т)", },
{"id": "1341","name": "Чеборкульская (Ц-Г)", },
{"id": "1342","name": "Чеботарева (Ц-Г)", },
{"id": "1343","name": "Чебышева (Дг)", },
{"id": "1344","name": "Чеговца (И)", },
{"id": "1345","name": "Чекалина (Ц-Г)", },
{"id": "1346","name": "Челюскинцев (Ц-Г)", },
{"id": "1347","name": "Челябинская (Т)", },
{"id": "1348","name": "Чемпионов (Ц-Г)", },
{"id": "1842","name": "Червоная пл. (Мт)", },
{"id": "1776","name": "Червоногирнычанская (Ж)", },
{"id": "1350","name": "Черемуховая (Дг)", },
{"id": "1351","name": "Черемшины (Ц-Г)", },
{"id": "1352","name": "Черепановых (Ц-Г)", },
{"id": "1777","name": "Череповецкая (И)", },
{"id": "1353","name": "Черешневая (Мт)", },
{"id": "1354","name": "Черкасова (Т)", },
{"id": "1355","name": "Черкасская (Дг)", },
{"id": "1356","name": "Черниговская (Т)", },
{"id": "1357","name": "Черновицкая (Т)", },
{"id": "1358","name": "Черногорская (Дг)", },
{"id": "1359","name": "Черноморская (Дг)", },
{"id": "1778","name": "Чернышева (Ц-Г)", },
{"id": "1360","name": "Чернышевского (И)", },
{"id": "1361","name": "Черняховского (Ц-Г)", },
{"id": "1362","name": "Черского (Мт)", },
{"id": "1363","name": "Чехова пер. (Т)", },
{"id": "1364","name": "Чехословацкая (С)", },
{"id": "1365","name": "Чехословацкий пер.(С)", },
{"id": "1366","name": "Чеченская (Дг)", },
{"id": "1367","name": "Чешская (Ц-Г)", },
{"id": "1368","name": "Чиатурская (Пк)", },
{"id": "1369","name": "Чигиринская (Ц-Г)", },
{"id": "1370","name": "Чигорина (С,Пк)", },
{"id": "1371","name": "Чижевского (Дг)", },
{"id": "1372","name": "Чистопольская (С)", },
{"id": "1373","name": "Читателей (Мт)", },
{"id": "1779","name": "Читинская (И)", },
{"id": "1375","name": "Чкалова (Ц-Г)", },
{"id": "1377","name": "Чувашская (Т)", },
{"id": "1378","name": "Чугуева (Ц-Г)", },
{"id": "1379","name": "Чугунная (Т)", },
{"id": "1073","name": "Чугуноливарная (Мт)", },
{"id": "1380","name": "Чудская (Ц-Г)", },
{"id": "1381","name": "Чуковского (И)", },
{"id": "1382","name": "Чукотская (Т)", },
{"id": "423","name": "Чумацкая (Ц-Г)", },
{"id": "1846","name": "Чумацький шлях (И)", },
{"id": "1383","name": "Чусовская (Пк)", },
{"id": "1384","name": "Шаблия (Ц-Г)", },
{"id": "1385","name": "Шадринская (Т)", },
{"id": "1386","name": "Шаляпина (И)", },
{"id": "1780","name": "Шапорина (Т)", },
{"id": "1389","name": "Шапошникова (Пк)", },
{"id": "1390","name": "Шарохина (Ц-Г)", },
{"id": "1391","name": "Шатурская (Т)", },
{"id": "1781","name": "Шахматная (Ж)", },
{"id": "1393","name": "Шахтерская (Т)", },
{"id": "391","name": "Шахтерской славы (С)", },
{"id": "1075","name": "Шахтерской славы (Ц-Г)", },
{"id": "1835","name": "Шверника (Т)", },
{"id": "1395","name": "Швецова (Ц-Г)", },
{"id": "1396","name": "Шевцовой (И)", },
{"id": "1397","name": "Шевченко (Ц-Г)", },
{"id": "1398","name": "Шекспира (С)", },
{"id": "1400","name": "Шепетовская (Дг)", },
{"id": "1782","name": "Шиллера (Ц-Г)", },
{"id": "1401","name": "Ширван-Заде (Дг)", },
{"id": "1584","name": "Широкая (Т)", },
{"id": "1402","name": "Широковская (Ц-Г)", },
{"id": "1859","name": "Широковское шоссе (И)", },
{"id": "1405","name": "Шиферная (Мт)", },
{"id": "1406","name": "Шишкина (Т)", },
{"id": "1407","name": "Шкапенко (Пк)", },
{"id": "1408","name": "Школьная (Ц-Г)", },
{"id": "1409","name": "Шконды (Пк)", },
{"id": "1410","name": "Шмаковская (Дг)", },
{"id": "874","name": "Шмаковская (С)", },
{"id": "1411","name": "Шмидта (Ц-Г)", },
{"id": "1412","name": "Шокальского (Ц-Г)", },
{"id": "1413","name": "Шопена (Ц-Г)", },
{"id": "94","name": "Шоу (Дг)", },
{"id": "1417","name": "Шпагина (Т)", },
{"id": "1418","name": "Шпака (И)", },
{"id": "1419","name": "Шполянская (Дг)", },
{"id": "1421","name": "Штеровская (Пк)", },
{"id": "1422","name": "Штрауса (Ц-Г)", },
{"id": "1783","name": "Шуберта (Дг)", },
{"id": "1423","name": "Шубина (Ц-Г)", },
{"id": "1424","name": "Шувалова (И)", },
{"id": "1425","name": "Шуйская (Ц-Г)", },
{"id": "1426","name": "Шулейкина (Дг)", },
{"id": "1427","name": "Шульги (Ц-Г)", },
{"id": "1428","name": "Шумана (Т)", },
{"id": "1429","name": "Шумского пер. (Т)", },
{"id": "1430","name": "Шурупова (Пк)", },
{"id": "1431","name": "Шухова (Пк)", },
{"id": "1784","name": "Шушенская (Т)", },
{"id": "1433","name": "Щегловского (Т)", },
{"id": "1434","name": "Щеголева (Дг)", },
{"id": "602","name": "Щедрая (И)", },
{"id": "1435","name": "Щепкина (Пк)", },
{"id": "1613","name": "Щербакова (Мт)", },
{"id": "1785","name": "Щирского (Ц-Г)", },
{"id": "1438","name": "Щукина (Дг)", },
{"id": "1439","name": "Эвенкийская (Т)", },
{"id": "1440","name": "Эдисона (Ц-Г)", },
{"id": "1441","name": "Эйнштейна (Ц-Г)", },
{"id": "1442","name": "Экипажная (Мт)", },
{"id": "1443","name": "Экономическая (Ц-Г)", },
{"id": "1585","name": "Экономический пер. (Ц-Г)", },
{"id": "1445","name": "Электрозаводская (Пк)", },
{"id": "1446","name": "Электроники (Ц-Г)", },
{"id": "1447","name": "Электростальская (Пк)", },
{"id": "1448","name": "Элистинская (Пк)", },
{"id": "1450","name": "Энергетиков (Т)", },
{"id": "1836","name": "Энергетическая (И)", },
{"id": "1451","name": "Энтузиастов (Ц-Г)", },
{"id": "1452","name": "Эстонская (Пк)", },
{"id": "1796","name": "Юбилейная (Пк)", },
{"id": "1453","name": "Юбилейный мн. (С)", },
{"id": "1454","name": "Ювелирная (Дг)", },
{"id": "1455","name": "Югоковская (И)", },
{"id": "1456","name": "Южина (Т)", },
{"id": "1457","name": "Южная (Дг)", },
{"id": "1458","name": "Южный пр. (И)", },
{"id": "1460","name": "Юная (И)", },
{"id": "1837","name": "Юнга (Т)", },
{"id": "1461","name": "Юности (Т)", },
{"id": "1786","name": "Юношеская (С)", },
{"id": "1463","name": "Юона (Т)", },
{"id": "1464","name": "Юрия Долгорукого (Дг)", },
{"id": "1849","name": "Юрия Кажанова (И)", },
{"id": "843","name": "Юрия Каминского (Мт)", },
{"id": "1071","name": "Юрия Смирнова (Т)", },
{"id": "65","name": "Юрия Сыча (Ц-Г)", },
{"id": "1465","name": "Юрчака (Пк)", },
{"id": "1466","name": "Юрьевская (Т)", },
{"id": "1470","name": "Яблоневая (Пк)", },
{"id": "1468","name": "Яблонского (Т)", },
{"id": "1469","name": "Яблочкиной (Ц-Г)", },
{"id": "1471","name": "Яворницкого (Т)", },
{"id": "1472","name": "Яворов (Мт)", },
{"id": "1473","name": "Ягодная (Ц-Г)", },
{"id": "1787","name": "Якоби (Ж)", },
{"id": "191","name": "Якова Белевцева (Мт)", },
{"id": "1475","name": "Яковлева (Мт)", },
{"id": "1476","name": "Якуба Коласа (Т)", },
{"id": "1477","name": "Якутская (Т)", },
{"id": "1478","name": "Якушкина (Пк)", },
{"id": "1479","name": "Ялтинская (Пк)", },
{"id": "1480","name": "Ямальская (Пк)", },
{"id": "1481","name": "Яна Гуса (Ц-Г)", },
{"id": "1483","name": "Янки Купалы (Дг)", },
{"id": "1484","name": "Яновского (И)", },
{"id": "1788","name": "Янтарная (Т)", },
{"id": "866","name": "Ярослава Мудрого (С,Дг)", },
{"id": "1485","name": "Ярославская (И)", },
{"id": "1486","name": "Яроша (Пк)", },
{"id": "1487","name": "Ярошенко (Пк)", },
{"id": "1488","name": "Ясеней (Мт)", },
{"id": "1489","name": "Ясная (И)", },
{"id": "1490","name": "Яцкива (Ц-Г)",}
]
export default novakomStreets