var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mt-lg-6",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"12","lg":"10","xl":"8"}},[_c('v-data-table',{staticClass:"elevation-1 pa-3",attrs:{"headers":_vm.headers,"loading-text":"Завантаження...","no-data-text":"Нема данних для відображення","items":_vm.users,"options":_vm.options,"loading":_vm.loading,"focusable":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',{on:{"click":function($event){return _vm.checkGetAbonents()}}},[_c('h2',{staticClass:"mr-6"},[_vm._v("Користувачі")])]),_c('v-spacer'),_c('AddUserModal',{ref:"addOrderDialog",on:{"add-change-user":function($event){_vm.updateTable += 1}}})],1)]},proxy:true},{key:"item.index",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.users.indexOf(item) + 1))])]}},{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [(item.is_active)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"deep-orange"}},[_vm._v("mdi-minus")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(((item.first_name) + " " + (item.last_name))))])]}},{key:"item.user_type",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.users_type.find(function (x) { return x.id == [item.user_type]; }).name))])]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.$vuetify.breakpoint.smAndDown},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.addOrderDialog.show(item)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Редагувати")])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }