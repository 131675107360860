<template>
    <div class="admin">
        <v-navigation-drawer
        class=""
        v-model="drawer"
        :clipped="$vuetify.breakpoint.mdAndUp"
        
        app
        left
        >
        <template v-slot:prepend>
            <v-list-item class="mt-5" two-line>
                <v-list-item-avatar>
                    <img v-if="user.avatar" :src="$router.app.devUrl + '/media/' + user.avatar" alt="avatar">
                    <v-icon v-else @click="is_component = ''" color="primary" large>mdi-account-circle</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>{{ user.username }}</v-list-item-title>
                    <!--<v-list-item-subtitle>{{ user.is_superuser && 'superuser' || user.buyer &&  'buyer' || 'client'}}<span v-if="user.client && user.client.is_profit" class="primary--text"> ( profit )</span></v-list-item-subtitle>-->
                </v-list-item-content>
            </v-list-item>

        </template>

        <v-divider></v-divider>

        <v-list shaped dense>
            
                <v-list-item
                :to="item.to"
                :id="item.component"
                v-for="(item, i) in menus"
                :key="i"
                @click="is_component = item.component"
                >
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
        </v-list>

        <v-divider></v-divider>
            <v-list shaped dense>

                <v-subheader>Налаштування</v-subheader>
                
                <v-list-item-group  v-model="item" color="primary">
                    <v-list-item
                    :id="item.component"
                    v-for="(item, i) in items"
                    :key="i"
                    @click="is_component = item.component"
                    >
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
                
                
            </v-list>
        </v-navigation-drawer>
        
        <v-container fluid class="mt-12">
            <v-row align="center" justify="center">
                <transition name="page" mode="out-in">
                    <router-view></router-view>
                </transition>
            </v-row>

            <v-row align="center" justify="center">
                <transition name="page" mode="out-in">    
                    <component :is="is_component"></component>
                </transition>
            </v-row>
        </v-container>
    
        <v-btn
        v-if="$vuetify.breakpoint.mdAndDown"
            bottom
            color="primary "
            dark
            fab
            fixed
            left
            @click="drawer = !drawer"
            >
            <v-app-bar-nav-icon />
        </v-btn>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import "@/components/Admin/Menu"
//import "@/components/Admin/Settings"


export default {
    name: 'admin',
    data: () => ({
        is_component: "",  
        drawer: null,
        item: '',
        menu: '',
        menus: [
            { title: 'Користувачі', icon: 'mdi-account-multiple', to:"/admin/users" },
            { title: 'Вулиці', icon: 'mdi-home-edit', to:"/admin/streets" },
            { title: 'Вулиці_v2', icon: 'mdi-home-edit', to:"/admin/streets2" },
            { title: 'Тарифи', icon: 'mdi-layers', to:"/admin/rates" },
            { title: 'Оператори', icon: 'mdi-layers', to:"/admin/operators" },
            { title: 'Типи Платежів', icon: 'mdi-layers', to:"/admin/type_pays" },
            { title: 'Типи Послуг', icon: 'mdi-layers', to:"/admin/type_service" },
            { title: 'Типи Заявок', icon: 'mdi-layers', to:"/admin/type_job" },
            { title: 'Типи Статусів', icon: 'mdi-layers', to:"/admin/type_status" },

        ],
        items: [
            { title: 'Додати корістувача', icon: 'mdi-account-plus', component: "AddUser" },
            { title: 'Додати тариф', icon: 'create', component: "AddRates" },

        ],
        admins: [
            ['Management', 'mdi-account-multiple-outline'],
            ['Settings', 'mdi-cog-outline'],
        ],
        cruds: [
            ['Create', 'mdi-plus-outline'],
            ['Read', 'mdi-file-outline'],
            ['Update', 'mdi-update'],
            ['Delete', 'mdi-delete'],
        ],
    }),
    components: {
        //
    },
    computed: {
        ...mapGetters({
        user: "auth/user"
        }),
    },
    mounted() {
        this.$store.dispatch('auth/fetchUser');
    },
}
</script>