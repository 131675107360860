<template>
	<div class="add-order-modal">
        
        <v-dialog v-model="dialog" persistent max-width="600">
            <template v-slot:activator="{ on }">
                <v-btn
                    color="primary"
                    v-on="on"
                >
                    <v-icon left>mdi-plus</v-icon>
                    Додати
                </v-btn>
            </template>
            <v-card>
            <v-card-title>
            <span class="headline">{{ userData.title }} <span v-if="form_data.username && changeForm">"{{ form_data.username }}"</span></span>
            </v-card-title>
            <v-card-text>
            <v-container>
                <v-form 
                ref="form"
                v-model="valid"
                lazy-validation>
                    <v-row>
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field 
                        label="Ім'я"
                        v-model="form_data.first_name"
                        name="first_name"
                        :rules="textRules"
                        type="text"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field 
                        label="Прізвище"
                        v-model="form_data.last_name"
                        name="last_name"
                        :rules="textRules"
                        type="text"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field
                        label="E-mail"
                        v-model="form_data.email"
                        :rules="emailRules"
                        name="email"
                        type="email"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-select
                        v-model="form_data.user_type"
                        :rules="textRules"
                        :items="users_type"
                        label="Тип"
                        item-text="name"
                        item-value="id"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field
                        label="Логін"
                        v-model="form_data.username"
                        :rules="textRules"
                        name="username"
                        type="text"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field
                        label="Пароль"
                        v-model="form_data.password"
                        :rules="!changeForm && textRules || notRules"
                        name="password"
                        type="password"
                        >
                        </v-text-field>
                    </v-col>
                    
                    
                    
                    
                    
                    </v-row>
                </v-form>
            </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary lighten-1" text @click="closeDialog()">Закрити</v-btn>
                <v-spacer></v-spacer>

                <v-btn v-if="!changeForm" color="primary lighten-1" text @click="reset()">Скинути</v-btn>
                <template v-else>
                    <v-btn v-if="form_data.is_active" :loading="loadingOff" color="deep-orange lighten-1" text @click="offOnUser()"><v-icon left>mdi-minus</v-icon>Вимкнути</v-btn>
                    <v-btn v-else :loading="loadingOff" color="primary lighten-1" text @click="offOnUser()"><v-icon left>mdi-check</v-icon>Увімкнути</v-btn>

                <v-spacer />
                </template>

                <v-btn :loading="loadingSubmit" color="primary lighten-1" @click="sendForm()">Зберегти</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
			
	</div>
</template>

<script>

    import axios from "axios"
    import { mapGetters } from "vuex"
	export default {
        name: 'add-user-modal',
		data: () => ({
            loadingSubmit:false,
            loadingOff: false,
            changeForm: false,
            item: {},
            userData: {},
            defaultUserData: {
                add: {title: "Додати Користувача", method: "POST", readonly: false, message: "Користувача створено!"},
                change: {title: "Користувач", method: "PATCH", readonly: true, message: "Данні користувача змінено!"}
            },
            dialog: false,
            valid: true,
            form_data:{
                first_name: "",
                last_name: "",
                password: "",
                username: "",
                email: "",
                user_type: ""
            },
            
            textRules: [
                v => !!v || "Це поле обов'язкове",
            ],
            notRules: [
                v => !!v || true ,
            ],
            emailRules: [
                v => !v && true || /.+@.+\..+/.test(v) || 'E-mail не валідний',
            ],
            
        }),
        computed: {
            ...mapGetters({
            users_type: "users/users_type"
            }),
        },
        mounted(){
            this.userData = JSON.parse(JSON.stringify(this.defaultUserData.add));

        },
        methods: {
            show(item){
                //console.log(order);
                this.dialog = true;
                this.userData = JSON.parse(JSON.stringify(this.defaultUserData.change));
                this.changeForm = true;
                this.form_data = JSON.parse(JSON.stringify(item));
            },
            validate () {
                if (this.$refs.form.validate()) {
                this.snackbar = true;
                }
            },
            reset () {
                this.$refs.form.reset();
            },
            closeDialog (){
                this.dialog = false
                this.reset() 
                this.changeForm = false
                this.userData = JSON.parse(JSON.stringify(this.defaultUserData.add));
            },
            offOnUser(){
                this.loadingOff = true;
                axios({
                        headers: { "Content-type": "application/json; charset=UTF-8" },
                        method: "PATCH",
                        url: "/api/user/get_users/",
                        data: {id: this.form_data.id, is_active: !this.form_data.is_active},
                    })
                    .then(() => {
                        //console.log(response);
                        this.$emit('add-change-user');
                        this.$router.app.$sheet.show(`Користувача ${this.form_data.is_active && 'Вимкнено!' || 'Увімкнено!'}`);
                        this.dialog = false;
                        this.reset();
                    })
                    .catch(err => {
                        //reject(err)
                        //console.log(err);
                        this.$router.app.$sheet.show("Помилка!", err);
                        
                        //console.log(this.$route.query.next)
                    })
                    .finally(() => (this.loadingOff = false))
            },
            sendForm() {
                //console.log(this.form_data);
                
                if(this.$refs.form.validate()){
                    this.loadingSubmit = true;
                    
                    axios({
                        headers: { "Content-type": "application/json; charset=UTF-8" },
                        method: this.userData.method,
                        url: "/api/user/get_users/",
                        data: this.form_data,
                    })
                    .then(() => {
                        //console.log(response);
                        this.loadingSubmit = false;
                        this.$emit('add-change-user');
                        this.$router.app.$sheet.show(this.userData.message);
                        this.dialog = false;
                        this.reset();
                    })
                    .catch(err => {
                        //reject(err)
                        //console.log(err);
                        this.loadingSubmit = false;
                        this.$router.app.$sheet.show("Помилка!", err);
                        
                        //console.log(this.$route.query.next)
                    });
                }
                
            }
        }
    }
</script>