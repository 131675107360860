<template>
  
  <v-container >
    <v-row align="center" justify="center" class="my-6">
      <h1>Вулиці</h1>
    </v-row>

    <v-row align="center" justify="center" class="my-5">
      <v-col cols="12" md="8">
        <v-autocomplete
          auto-select-first
          chips
          deletable-chips
          multiple
          v-model="newStreets"
          :items="novakomStreets"
          item-text="name"
          item-value="id"
          label="Список вулиць"
          hint="Додайте необхідні вулиці"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="my-5">
      <v-btn color="primary" @click="saveStrees" :disabled="!newStreets" >Зберегти</v-btn>
    </v-row>
  </v-container>

  
</template>
<script>
import novakomStreets from '@/components/Admin/novakomStreets.js'
import { mapGetters } from "vuex"
export default {
    name: 'ViewStreets',
    data: () => ({
        novakomStreets: novakomStreets,
        newStreets: []
    }),
    computed: {
        ...mapGetters({
          streets: "streets/streets"
        }),
    },
    mounted(){

      this.newStreets = this.streets && this.streets.filter(x => x && x.id)
    },
    methods:{
      saveStrees(){
        let tempStreets = []
        this.newStreets.forEach(element => {this.novakomStreets.find(x => x.id == element && tempStreets.push(x))})
        this.$store.dispatch('streets/saveStreets', {
          streets: tempStreets
        })
        .then(
          this.$router.app.$snack.show("Збережено")
        )
      }
    }
}
</script>