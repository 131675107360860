<template>
  
  <v-container >
    <v-row justify="center" class="mt-lg-6">
      <v-col  md="12" lg="10" xl="8">
        <v-data-table
          :headers="headers"
          loading-text="Завантаження..."
          no-data-text="Нема данних для відображення"
          :items="data"
          :options.sync="options"
          :loading="loading"
          class="elevation-1 pa-3"
          focusable
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title class=""><h2 class="mr-6">Типи Заявок</h2></v-toolbar-title>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-col>
      </v-row>
  </v-container>
</template>

<script>
  import axios from "axios"
  export default {
    name: "ViewTypeJob",
    data: () => ({
      updateTable: 0,
      data: [],
      loading: false,
      options: {},
      headers: [
        { text: 'ID', value: 'id', sortable: false, },
        { text: "Назва", value: 'name', sortable: false, },
        { text: 'mask', value: 'mask', sortable: false, }, 
      ],
    }),
    mounted(){
        this.getData();
    },
    methods: {
      async getData(){
        this.loading = true;
        //this.filterFormat();
        try {
          const { data } = await axios.get('/api/service/list_order_type/');
          console.log(data)
          this.data = data;
          this.loading = false;
        } catch (error) {
          console.log(error)
          this.loading = false;
        }    
      },
    },
  }
</script>