<template>
  
  <v-container >
    <v-row justify="center" class="mt-lg-6">
      <v-col  md="12" lg="10" xl="8">
        <v-data-table
          :headers="headers"
          loading-text="Завантаження..."
          no-data-text="Нема данних для відображення"
          :items="users"
          :options.sync="options"
          :loading="loading"
          class="elevation-1 pa-3"
          focusable
        >
          <template v-slot:top>

            <v-toolbar flat color="white">
              <v-toolbar-title @click="checkGetAbonents()" class=""><h2 class="mr-6">Користувачі</h2></v-toolbar-title>
            
              <v-spacer></v-spacer>

              
              <AddUserModal ref="addOrderDialog" v-on:add-change-user="updateTable += 1"/>

            </v-toolbar>
            
          </template>

        <!-- ---------------------------------------- Cols ----------------------------------------- -->

        <template #item.index="{ item }">
              <span>{{ users.indexOf(item) + 1 }}</span>
        </template>
        <template #item.is_active="{ item }">
          <v-icon color="primary" v-if="item.is_active">mdi-check</v-icon>
          <v-icon color="deep-orange" v-else>mdi-minus</v-icon>
        </template>
        <template #item.name="{ item }">
          <span>{{ `${item.first_name} ${item.last_name}` }}</span>
        </template>
        <template #item.user_type="{ item }">
          <span>{{ users_type.find(x => x.id == [item.user_type]).name }}</span> 
        </template>
        <template #item.options="{ item }">
          <v-tooltip bottom :disabled="$vuetify.breakpoint.smAndDown">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="$refs.addOrderDialog.show(item)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
            </template>
            <span>Редагувати</span>
          </v-tooltip>
        </template>
        <!-- ---------------------------------------- /Cols ----------------------------------------- -->
        
        </v-data-table>
      </v-col>
      </v-row>
  </v-container>
</template>

<script>
  import axios from "axios"
  import { mapGetters } from "vuex"
  import AddUserModal from '@/components/Admin/Menu/AddUserModal.vue'
  export default {
    name: "ViewUser",
    comments:{
      AddUserModal
    },
    data: () => ({
      updateTable: 0,
      users: [],
      loading: false,
      options: {},
      headers: [
        { text: '#', value: 'index', sortable: false,  width: 50},
        //{ text: '#', value: 'id', sortable: false,  width: 50},
        { text: 'Логин', value: 'username', sortable: false, },
        { text: 'Статус', value: 'is_active' },
        { text: "Ім'я", value: 'name', sortable: false, },
        { text: 'Тип', value: 'user_type', },
        { text: 'Телефон', value: 'phone', sortable: false, },
        { text: 'Опції', value: 'options', sortable: false, }, 
      ],
    }),
    computed: {
        ...mapGetters({
        users_type: "users/users_type"
        }),
    },
    watch: {
      options: {
        handler () {
          this.getUsers();
        },
        deep: true,
      },
      updateTable(){
        this.updateTable && this.getUsers();
      },
    },
    methods: {
      async getUsers(){
        this.loading = true;
        //this.filterFormat();
        try {
          const { data } = await axios.get('/api/user/get_users/', {params: {}});
          //console.log(data)
          //this.paginations = data.paginations;
          this.users = data;
          this.loading = false;
        } catch (error) {
          console.log(error)
          this.loading = false;

        }    
      },
    },
  }
</script>