<template>
  
  <v-container >
    <v-row justify="center" class="mt-lg-6">
      <v-col  md="12" lg="10" xl="8">
        <v-data-table
          :headers="headers"
          loading-text="Завантаження..."
          no-data-text="Нема данних для відображення"
          :items="streets"
          :options.sync="options"
          :server-items-length="paginations.count"
          :loading="loading"
          class="elevation-1 pa-3"
          focusable
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title class=""><h2 class="mr-6">Вулиці</h2></v-toolbar-title>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-col>
      </v-row>
  </v-container>
</template>

<script>
  import axios from "axios"
  export default {
    name: "ViewStreets2",
    data: () => ({
      updateTable: 0,
      streets: [],
      loading: false,
      options: {},
      paginations: {},
      headers: [
        { text: 'ID', value: 'id', sortable: false, },
        { text: "Назва", value: 'street', sortable: false, }
      ],
    }),
    watch: {
      options: {
        handler () {
          this.getStreets();
        },
        deep: true,
      },
    },
    methods: {
      async getStreets(){
        this.loading = true;
        //this.filterFormat();
        try {
          const { data } = await axios.get('/api/cabletv/list_street/', {params: this.filterFormat()});
          console.log(data)
          this.streets = data.results;
          this.paginations = data.paginations;
          this.loading = false;
        } catch (error) {
          console.log(error)
          this.loading = false;
        }    
      },
      filterFormat() {
        const temp = {};
        temp.itemsPerPage = this.options.itemsPerPage;
        temp.page = this.options.page;
        return temp; 
      },
    },
  }
</script>